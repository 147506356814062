// Copyright 2022 Epic Systems Corporation

// Style for actions buttons forked from MyChart command button (see _CommandButton.scss in PatientEngagement)
@use "../../styles/_colors.scss" as *;
@use "../../styles/_utilities.scss" as *;
@use "../../styles/theme/colorUtils.scss" as *;

$padding-vertical: 4px;
$padding-horizontal: 12px;

$slim-padding-vertical: 1px;
$slim-padding-horizontal: 6px;

// define the pulse animation
@mixin pulse-animation($background-color) {
	@keyframes buttonPulse {
		from {
			box-shadow: 0 0 0 0 adjust-color($background-color, $alpha: -0.3);
		}

		to {
			box-shadow: 0 0 15px 40px adjust-color($background-color, $alpha: -1);
		}
	}
	animation: buttonPulse 3s cubic-bezier(0.18, 0.32, 0, 1),
		buttonPulse 3s infinite cubic-bezier(0.18, 0.32, 0, 1) 13s;
}

$narrow-border: 1px;
$wide-border: 2px;

@mixin buttonPadding($is-slim: false, $border-width: 0px) {
	@if $is-slim == true {
		padding: calc(#{$slim-padding-vertical} - #{$border-width})
			calc(#{$slim-padding-horizontal} - #{$border-width});
	}
	@if $is-slim == false {
		padding: calc(#{$padding-vertical} - #{$border-width})
			calc(#{$padding-horizontal} - #{$border-width});
	}
}

.actionButton {
	@include buttonPadding($is-slim: false);
	border: none;
	border-radius: 4px;

	svg {
		height: 1.5em; // Prevent action button icons from taking up more than 1 character's worth of text. This is the max height for an icon, which is the same as line-height
		width: 1.5em;
		fill: currentColor;
		margin-right: 0.3em;
	}
	&.iconOnly svg {
		margin-right: 0;
	}

	box-shadow: inset 0 -2px 0 0 $WPColor-ShadowVerySoft;
	cursor: pointer;
	line-height: 1.5em;
	outline: none;

	div {
		outline: none;
	}

	&.slim {
		@include buttonPadding($is-slim: true);
	}

	&:active,
	&.active {
		// make the button appear "pushed in";
		box-shadow: none;
		transform: translateY(1px);
	}

	// Default (light-themed colors)
	background-color: $WPColor-ActionButtonDefaultBack;
	color: $WPColor-ActionButtonFore;
	&.pulse:not(.disabled) {
		@include pulse-animation($WPColor-ActionButtonDefaultBack);
	}

	@include focusedOrHovered() {
		background-color: $WPColor-ActionButtonDefaultHoverBack;
	}

	&.negative {
		background-color: $WPColor-ActionButtonNegativeBack;

		@include focusedOrHovered() {
			background-color: $WPColor-ActionButtonNegativeHoverBack;
		}
		&.pulse:not(.disabled) {
			@include pulse-animation($WPColor-ActionButtonNegativeHoverBack);
		}
	}

	&.positive {
		background-color: $WPColor-ActionButtonPositiveBack;

		@include focusedOrHovered() {
			background-color: $WPColor-ActionButtonPositiveHoverBack;
		}
		&.pulse:not(.disabled) {
			@include pulse-animation($WPColor-ActionButtonPositiveBack);
		}
	}

	// Disabled button style must always take precedence over other styles
	&.disabled {
		@include focusedOrHovered() {
			background-color: $WPColor-ActionButtonDisabledBack;
		}

		background-color: $WPColor-ActionButtonDisabledBack;
		color: $WPColor-ActionButtonDisabledFore;
		cursor: default;
		box-shadow: none;
		transform: none;
		animation: none;
	}
}

.actionButton.secondary:not(.disabled) {
	border: $narrow-border solid currentColor;
	box-shadow: none;
	@include buttonPadding($is-slim: false, $border-width: $narrow-border);

	&.slim {
		@include buttonPadding($is-slim: true, $border-width: $narrow-border);
	}

	&:active,
	&.active {
		// make the button appear "pushed in";
		border-color: $WPColor-BorderInvisible;
		box-shadow: inset 0 0 0 2px currentColor;
		transform: none; // override active style from primary (to support backwards compatibility)
	}

	// Default (light-themed colors)
	background-color: $WPColor-BorderedActionButtonDefaultBack;
	color: $WPColor-BorderedActionButtonDefaultFore;

	@include focusedOrHovered() {
		background-color: $WPColor-BorderedActionButtonDefaultHoverBack;
		color: $WPColor-BorderedActionButtonDefaultHoverFore;
	}

	&.negative {
		color: $WPColor-BorderedActionButtonNegativeFore;

		@include focusedOrHovered() {
			background-color: $WPColor-BorderedActionButtonNegativeHoverBack;
			color: $WPColor-BorderedActionButtonNegativeHoverFore;
		}
	}

	&.positive {
		color: $WPColor-BorderedActionButtonPositiveFore;

		@include focusedOrHovered() {
			background-color: $WPColor-BorderedActionButtonPositiveHoverBack;
			color: $WPColor-BorderedActionButtonPositiveHoverFore;
		}
	}
}

// Dark color scheme
// This style is pulled from _CommandButton.scss MyChart styles
.dark.actionButton {
	box-shadow: none;

	&.disabled {
		// From MyChart `CoreStyle\CommandButton\_Disabled.scss`
		background-color: WPForceRGBA($DarkModeColor-PrimaryActionButtonDefaultFore, 0.2);
		color: WPForceRGBA($DarkModeColor-PrimaryActionButtonDefaultFore, 0.6);
		border: none;
	}

	&.primary:not(.disabled) {
		border: $wide-border solid;
		@include buttonPadding($is-slim: false, $border-width: $wide-border);

		&.slim {
			@include buttonPadding($is-slim: true, $border-width: $wide-border);
		}

		border-color: $DarkModeColor-PrimaryActionButtonDefaultBorder;
		color: $DarkModeColor-PrimaryActionButtonDefaultFore;
		background-color: $DarkModeColor-PrimaryActionButtonDefaultBack;

		@include focusedOrHovered() {
			background-color: $DarkModeColor-ActionButtonDefaultHoverBack;
			border-color: transparent;
		}

		&.negative {
			border-color: $DarkModeColor-PrimaryActionButtonNegativeBorder;
			color: $DarkModeColor-PrimaryActionButtonNegativeFore;
			background-color: $DarkModeColor-PrimaryActionButtonNegativeBack;

			@include focusedOrHovered() {
				background-color: $DarkModeColor-PrimaryActionButtonNegativeHoverBack;
				border-color: transparent;
			}
		}

		&.positive {
			border-color: $DarkModeColor-PrimaryActionButtonPositiveBorder;
			color: $DarkModeColor-PrimaryActionButtonPositiveFore;
			background-color: $DarkModeColor-PrimaryActionButtonPositiveBack;

			@include focusedOrHovered() {
				background-color: $DarkModeColor-PrimaryActionButtonPositiveHoverBack;
				border-color: transparent;
			}
		}
	}

	&.secondary:not(.disabled) {
		border-color: $DarkModeColor-NonPrimaryActionButtonDefaultBorder;
		color: $DarkModeColor-NonPrimaryActionButtonDefaultFore;
		background-color: $DarkModeColor-NonPrimaryButtonBackground;

		@include focusedOrHovered() {
			background-color: $DarkModeColor-NonPrimaryActionButtonDefaultHoverBack;
			border-color: transparent;
			color: $DarkModeColor-NonPrimaryActionButtonDefaultFore;
		}

		&.negative {
			border-color: $DarkModeColor-NonPrimaryActionButtonNegativeBorder;
			color: $DarkModeColor-NonPrimaryActionButtonNegativeFore;
			background-color: $DarkModeColor-NonPrimaryButtonBackground;

			@include focusedOrHovered() {
				background-color: $DarkModeColor-NonPrimaryActionButtonNegativeHoverBack;
				border-color: transparent;
				color: $DarkModeColor-NonPrimaryActionButtonNegativeFore;
			}
		}

		&.positive {
			border-color: $DarkModeColor-NonPrimaryActionButtonPositiveBorder;
			color: $DarkModeColor-NonPrimaryActionButtonPositiveFore;
			background-color: $DarkModeColor-NonPrimaryButtonBackground;

			@include focusedOrHovered() {
				background-color: $DarkModeColor-NonPrimaryActionButtonPositiveHoverBack;
				border-color: transparent;
				color: $DarkModeColor-NonPrimaryActionButtonPositiveFore;
			}
		}
	}
}
