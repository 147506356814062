// Copyright 2021 Epic Systems Corporation

@use "../../styles/_dimensions.scss" as *;
@import "../../styles/_colors.scss";
@import "../../styles/_utilities.scss";

// NOTE: All video sizing in this file is based on the assumption that we will have no more than five users in the call and four video feeds in the participant strip.
// Any updates to this would have to either further shrink the individual video feeds or introduce introduce functional options to remove, resize, or scroll through available feeds.
.videoStrip {
	display: flex;
	align-items: center;
	position: relative;
	flex-direction: column;
	justify-content: flex-end;
	background: rgba(0, 0, 0, 0.9);
	width: 30%;
	height: inherit;

	video {
		height: 100%;
		width: 100%;
		position: relative;
		margin: 0;
	}
}

.videoStrip.twoParticipantCall {
	display: none;
}

.moreParticipants {
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	padding: 10px;
	font-size: 24px;
	width: 100%;
	background-color: #181818;
	outline: none;
	border: none;

	& > div {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
	}

	.iconWrapper {
		display: flex;
		align-items: center;
		margin-left: 5px;
	}

	svg {
		fill: #fff;
	}

	@include focusedOrHovered() {
		color: $WPColor-BrandAccentBack;

		svg {
			fill: $WPColor-BrandAccentBack;
		}
	}
}

//For any screen that is not wide enough to have the controls within the header, we should then render the video feeds horizontally along the bottom
@media only screen and (max-width: $screen-width-inflection) and (orientation: portrait) {
	.videoStrip {
		flex-direction: row;
		width: 100%;
		height: 25%;
	}

	.moreParticipants {
		height: 100%;
		width: unset;

		& > div {
			flex-direction: column;
		}

		.iconWrapper {
			margin: 0px;
		}
	}
}
