// Copyright 2021 Epic Systems Corporation

@use "../../styles/_colors.scss" as *;
@use "../../styles/_dimensions.scss" as *;

.participantWrapper {
	position: relative;
	display: flex;
	justify-content: center;
	flex: 1;
	width: 100%;
	max-height: 25%;
	top: 0;
	background-color: black;
	overflow: hidden;
}

.gridParticipant {
	position: relative;
	border: 1px solid $menu-border-color;
	overflow: hidden;

	video {
		width: 100%;
		height: 100%;
	}
}

// Border for local screen share preview
.screenSharePreview {
	border: 2px solid $EVCColor-ScreenSharePreviewBorder;
}

// How to style the main feed when the participant strip is horizontal
@media only screen and (max-width: $screen-width-inflection) and (orientation: portrait) {
	.participantWrapper {
		height: 100%;
		max-width: 34%;
		max-height: 100%;
	}
}

// when participant strip is vertical, but the header is hidden give the video feeds a bit more space
@media only screen and (max-height: $minimum-screen-size-for-header) and (orientation: landscape) {
	.participantWrapper {
		max-height: 34%;
	}
}

.hide {
	display: none;
}
