// Copyright 2021-2023 Epic Systems Corporation

@use "../../styles/_dimensions.scss" as *;
@import "_pinning.scss";
@import "../../styles/_colors.scss";
@import "../../styles/_utilities.scss";

// Scaling is currently based around having minimum size font and icons when screen width is at or below 600px.
// Both will begin scaling up to their max at larger widths. Scaling will stop at a maximum width of 1200px.
$icon-size-scale: max(1rem, min(0.5rem + 1.3333vw, 1.5rem));
$font-size-scale: max(0.75rem, min(0.5rem + 0.6667vw, 1rem));

// set font size for browsers that that don't support max and min (ios < 11.3, chromium < 79 etc)
// https://developer.mozilla.org/en-US/docs/Web/CSS/max()#browser_compatibility//
// modify these according to media window width at the bottom
$icon-size: 16px;
$font-size: 12px;

.wrapper {
	position: absolute;
	overflow: hidden;
	left: 1%;
	bottom: 1%;
	z-index: 5;
	max-width: 98%;
	background: rgba(0, 0, 0, 0.8);
	font-size: $font-size;
	// if the browser supports max/min this will override, otherwise it will use the default
	font-size: $font-size-scale;
	border: solid 3px transparent;
	border-radius: 16px;
	max-height: 99%;
	display: flex;
	flex-direction: column-reverse;
}

.infoBar {
	color: #ffffff;
	// Limit vertical padding but allow the text / icons to have horizontal spacing
	// Right needs less padding because all elements have a right margin spacer
	padding: 2.5px 0px 2.5px 5px;
	max-height: 40%;
	// Make sure full name can be displayed when showing the three-dot menu with small vertical resolution
	min-height: 30px;

	font-size: $font-size;
	// if the browser supports max/min this will override, otherwise it will use the default
	font-size: $font-size-scale;

	display: flex;
	align-items: center;
	border: solid 3px transparent;
	overflow: hidden;

	// This transform is necessary so that the info bar does not disappear on rotation
	transform: translateZ(0);
	-webkit-transform: translateZ(0);

	svg > * {
		fill: #ffffff;
	}

	svg {
		height: 100%;
		width: 100%;
	}

	.spacer {
		height: $icon-size;
		width: $icon-size;
		min-width: $icon-size;

		height: $icon-size-scale;
		width: $icon-size-scale;
		min-width: $icon-size-scale;

		margin-right: 4px;
	}
}

.controlBar {
	overflow-y: auto;
	scrollbar-color: white transparent;
	scrollbar-width: thin;
}

.dots {
	background: none;
	border: none;
	// Screen readers read the button more consistently if it has at least 1px width
	// Opacity needs to be non-zero so that the button can be focused on mobile Firefox when the user is unpinned
	width: 1px;
	opacity: 0.001;

	// must specify min-width or button size can get weird with long names
	min-width: 0px;
	margin-right: 0px;

	// Show the 3dot button when you hover over the parent, or focus the button itself, or if the menu is open
	:hover > div > div > &:not(.mobile),
	&:focus:not(.mobile),
	&.open {
		opacity: 1;
		width: unset;
		margin-right: 4px !important;
	}

	@include focusedOrHovered() {
		background: $EVCColor-MenuButtonHoverBack;
		border-color: $EVCColor-MenuButtonHoverBorder;
	}

	&.open {
		background: $EVCColor-MenuButtonHoverBack;
		border-color: $EVCColor-MenuButtonHoverBorder;
	}

	svg {
		height: 20px;
		width: 30px;
	}
}

.participantName {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	// Padding makes sure descenders don't get clipped around edges
	padding: 3px 1px;
	margin-right: 4px;
}

.wrapper.speaking {
	border-color: $WPColor-BrandAccentBack;
}

.nameBarButton {
	background: none;
	border: 1px solid rgba(0, 0, 0, 0);
	outline: none;
	padding: 0px;
	border-radius: 50%;

	// Show border when button is focused or hovered
	@include focusedOrHovered() {
		border-color: white;
	}

	// No focus marks on inner div
	.buttonDiv {
		border: none;
		outline: none;
	}
}

.pinButton {
	@include pinningIcon($icon-size, $icon-size-scale, "pinned", "mobile");

	// Font size changes between main participants and sidebar, this keeps font-size within the button consistent
	// Otherwise the pin icon alignment is inconsistent
	font-size: $icon-size;
	font-size: $icon-size-scale;

	margin-right: 0px;
	// Show the pin button when you hover over the parent, or focus the button itself
	:hover > div > div > &:not(.mobile),
	&:focus:not(.mobile) {
		@include activePinIcon($icon-size, $icon-size-scale);
		div.spacer {
			min-width: 100%;
		}
		margin-right: 4px;
	}

	div.spacer {
		height: 100%;
		width: 100%;
		min-width: 0px;
	}
}

.speakerButton {
	height: $icon-size;
	height: $icon-size-scale;

	overflow: hidden;

	div.spacer {
		height: 100%;
		width: 100%;
		min-width: 0px;
	}
}

.nameBarAccessibleLabel {
	position: absolute !important; /* Outside the DOM flow */
	height: 1px;
	width: 1px; /* Nearly collapsed */
	overflow: hidden;
	clip: rect(1px, 1px, 1px, 1px);
}

.infoBar.main {
	// use the larger icon size for font in the main participant
	font-size: $icon-size;

	font-size: $icon-size-scale;
}

// when the device is in portrait, avoid the iOS home bar at the bottom of the page
@media only screen and (max-width: $screen-width-inflection) and (orientation: portrait) {
	.infoBar:not(.main):not(.localPreview) {
		bottom: max(1%, env(safe-area-inset-bottom, 0px));
	}
}

// mixin to modify all necessary sizes to support non-max/min browsers
@mixin modify-sizes($mixin-font-size, $mixin-icon-size) {
	.infoBar {
		font-size: $mixin-font-size;

		.spacer {
			height: $mixin-icon-size;
			width: $mixin-icon-size;
			min-width: $mixin-icon-size;
		}
	}

	.infoBar.main {
		font-size: $mixin-icon-size;
	}

	.pinIcon {
		height: $mixin-icon-size;

		:hover > div > &:not(.mobile),
		&:focus:not(.mobile) {
			width: $mixin-icon-size;
			min-width: $mixin-icon-size;
		}
		&.pinned {
			width: $mixin-icon-size;
			min-width: $mixin-icon-size;
		}
	}
}

// resizing for non-max/min larger browsers
// use a piece-meal / step-wise approach to approximate our scaling over a few different screen-sizes
@supports not (font-size: $icon-size-scale) {
	// enforce minimums below below 800px
	@media only screen and (max-width: 800px) {
		@include modify-sizes(12px, 16px);
	}

	// use scaled value of 800px from (800px to 1000px)
	@media only screen and (max-width: 1000px) {
		@include modify-sizes(13.333px, 18.667px);
	}

	// use scaled value of 1000px from (1000px to 1200px)
	@media only screen and (max-width: 1200px) {
		@include modify-sizes(14.667px, 21.333px);
	}

	// allow maximums above 1200px
	@media only screen and (min-width: 1200px) {
		@include modify-sizes(16px, 24px);
	}
}
