// Copyright 2021 Epic Systems Corporation

@import "../../../styles/_utilities.scss";

.overlay {
	height: 100%;
	width: 100%;
	background: rgba(0, 0, 0, 0.8);
	position: absolute;
	z-index: 4; // Do not cover the participant name bar, only the video
}

.warning {
	position: absolute;
	top: 5px;
	right: 5px;
	padding: 2px 4px;
}
