// Copyright 2020-2022 Epic Systems Corporation

@use "../../../styles/_colors.scss" as *;

.deviceSetupRow {
	padding: 10px 0;
}

.deviceSetupRow:not(:first-child) {
	border-top: #d0d0d0 solid 1px;
}

.deviceSetupRow.darkTheme:not(:first-child) {
	border-top-color: $menu-border-color;
}

.hardwareTestRow {
	margin-bottom: 10px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;

	.baseRow {
		flex-direction: row;
		align-items: center;
		width: 100%;
	}

	.deviceStatusMessage {
		padding: 0;
		margin: 0 5px;
		text-align: start;
	}

	.dropdownAndMessage {
		display: flex;
		flex-direction: column;
		overflow: hidden;
		width: 100%;
	}
}

.hardwareTestRowLabel {
	margin-bottom: 2px;
}

.baseRow {
	display: flex;
	flex-direction: column;
	max-width: 100%;
}

.deviceStatusMessage {
	padding: 5px 0 0 0;
	text-align: center;
	color: $WPColor-TextSlightlySubtle;

	.darkTheme & {
		color: #959595;
	}
}
