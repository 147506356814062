// Copyright 2021-2022 Epic Systems Corporation

@use "sass:math";

// inflection point between when controls are shown in header vs. in button tray
// this is also the point where we want to display non-main participant video feeds horizontally vs. vertically
// this should match ScreenWidthInflectionPoint in the types/layout.ts
$screen-width-inflection: 850px;

// inflection point between when image capture preview pane is full screen or not
// this should match SmallScreenWidthInflectionPoint in the types/layout.ts
$small-screen-width-inflection: $screen-width-inflection - 300px;

// inflection point for anything smaller than the iPad Mini (6th gen)
// this can be used for anything that should look the same for desktop and tablets, but not phones
$tablet-width-inflection: 743px;

// Constants for scaling text within participant video placeholders ('Loading...' & display names)
$medium-screen-size-text-cutoff: 600px;
$small-screen-size-text-cutoff: 450px;

// inflection point for showing only the controls bar in the header
// this should match MinimumScreenSizeForHeader in types/layout.ts
$minimum-screen-size-for-header: 525px;

// inflection point to make icons smaller in participant control menu
$shrink-participant-menu-height: 1020px;

// Video preview sizes
$float-video-height-1: 240px; // Max height for the floating video
$float-video-height-2: 200px;
$float-video-height-3: 160px;
$float-video-height-4: 120px;
$float-video-height-minimized: 60px; // Height of the minimized/shrunk preview
$float-video-height-minimized-peeking: 75px;
$float-video-placeholder-width-factor: calc(4 / 3); // Conversion from height to placeholder width

$float-video-max-width-factor: calc(16 / 9); // Conversion from height to max width
// Calculate out the max widths so we can use them in media constraints
// Also add 10px for the margin
$float-video-width-max-1: 10px + math.ceil($float-video-height-1 * $float-video-max-width-factor);
$float-video-width-max-2: 10px + math.ceil($float-video-height-2 * $float-video-max-width-factor);
$float-video-width-max-3: 10px + math.ceil($float-video-height-3 * $float-video-max-width-factor);
$float-video-width-max-4: 10px + math.ceil($float-video-height-4 * $float-video-max-width-factor);

// Hardware test/waiting card
$card-width-very-large: 950px;
$card-width-large: 500px;
$card-width-small: 360px;
$card-height-est: 540px; // Estimation of the card height with standard text and one device warning
$card-height-short-est: 300px; // Estimated height for simple/short cards

$hardware-test-settings-panel: 350px;
$hardware-test-action-content: 550px;

// Header
$header-height: 61px;

// Video Grid
$paging-section-height: 50px;
$toggle-bar-height-adjustment: 55px;

// Background Effects
// per row constants should correspond with constants in types/backgrounds.ts
$background-options-per-row: 3; // matches DesktopInflectionElements
$background-options-per-row-small: 2; // matches MobileAndSmallInflectionElements
$background-option-width: 130px; // 110px thumbnail + 10px padding + 10px margin
$background-page-width: $background-option-width * $background-options-per-row; // 3 options on grid and inflection carousel
$background-page-width-small: $background-option-width * $background-options-per-row-small; // 2 options on small inflection carousel
$background-none-width: $background-page-width - 10px; // 3 options on grid and inflection carousel - 10px side margins
$background-none-width-small: $background-page-width-small - 10px; // 2 options on small inflection carousel - 10px side margins

// Carousel specific constants
$page-button-size: 30px; // keep this in sync with values for PageArrowButtonWidth and PageSelectorButtonWidth in their respective .tsx files
