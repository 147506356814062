// Copyright 2022-2024 Epic Systems Corporation

@use "./_colorUtils.scss" as *;

/// #MyChart Color Definitions
/// The following is an incomplete listing of the colors used in MyChart
/// The full list can be found at http://rocinante.epic.com/infrastructure/sassdoc
///
/// THIS FILE SHOULD ONLY BE IMPORTED DIRECTLY BY _colors.scss
/// Referencing that file will give access to any of the MyChart defined colors here

/// BASIC COLORS///
$_BasicColor-Black: #000000;
$_BasicColor-VeryDarkGray: #363636;
$_BasicColor-DarkGray: #565656;
$_BasicColor-Gray: #767676;
$_BasicColor-Silver: #cccccc;
$_BasicColor-Gainsboro: #dbdbdb;
$_BasicColor-LightGainsboro: #eaeaea;
$_BasicColor-LightWhitesmoke: #f8f8f8;
$_BasicColor-White: #ffffff;
$_BasicColor-Transparent: transparent;
$_BasicColor-DarkGreen: #0f784a;
$_BasicColor-LightBlue: #bfe1ff; // HTML Standard lightblue is #add8e6
$_BasicColor-Whitesmoke: #f2f2f2;
$_BasicColor-LightGainsboro: #eaeaea;
$_BasicColor-Silver: #cccccc;
$_BasicColor-VeryVeryLightGreen: #ccffd8;

/// BRANDING ///
$WPColor-BrandHeader: $WPColor-BrandPrimary;
$WPColor-BrandSecondary: #0091ea;
$WPColor-BrandTertiary: #00a1db;

/// SEMANTIC ///
$WPColor-PositiveBright: #09aa71;
$WPColor-PositiveDim: $_BasicColor-DarkGreen;
$WPColor-NegativeBright: #d80000;
$WPColor-NegativeDim: #d75e56;
$WPColor-DisabledBack: $_BasicColor-Gainsboro;
$WPColor-SoftWarningBack: #ffffa1;
$WPColor-VerySoftWarningBack: WPLighten($WPColor-SoftWarningBack, 50%);

/// BASE ELEMENTS
$WPColor-TextBody: $_BasicColor-VeryDarkGray;
$WPColor-TextStrong: $_BasicColor-Black;
$WPColor-TextSlightlySubtle: $_BasicColor-DarkGray;
$WPColor-TextSubtle: $_BasicColor-Gray;
$WPColor-TextSubtleOnColoredBack: $_BasicColor-Silver;
$WPColor-TextOnColoredBack: $_BasicColor-White;
$WPColor-TextPositive: $WPColor-PositiveDim;
$WPColor-TextNegative: $WPColor-NegativeBright;
$WPColor-Link: $WPColor-BrandTertiary;
$WPColor-LinkHover: WPDarken($WPColor-Link, 12.2%);
$WPColor-ThemedHeader: $WPColor-BrandPrimary;
$WPColor-BorderSubtle: $_BasicColor-Silver;
$WPColor-BorderVerySubtle: $_BasicColor-Gainsboro;
$WPColor-BorderInvisible: $_BasicColor-Transparent;
$WPColor-BorderSubtle: $_BasicColor-Silver;
$WPColor-BorderVeryDark: $_BasicColor-Black;
$WPColor-Shadow: $_BasicColor-Black;
$WPColor-ShadowVeryVerySoft: rgba($WPColor-Shadow, 0.1);
$WPColor-ShadowVerySoft: rgba($WPColor-Shadow, 0.2);
$WPColor-ShadowSoft: rgba($WPColor-Shadow, 0.4);
$WPColor-ShadowHard: rgba($WPColor-Shadow, 0.6);
$WPColor-ShadowWhite: $_BasicColor-White;
$WPColor-TransparentContainerDarkBack: rgba($_BasicColor-Black, 0.5); // found for welcome to MyChart page
$WPColor-FilledContainerSectionBack: $_BasicColor-LightWhitesmoke;

/// COMPONENTS ///
$WPColor-CardBack: $_BasicColor-White;
$WPColor-CardSectionSeparator: $WPColor-BorderVerySubtle;
$WPColor-CardRowsSeparator: $WPColor-BorderVerySubtle;
$WPColor-CardSupplementalSectionBack: $WPColor-FilledContainerSectionBack;
$WPColor-ActionButtonFore: $WPColor-TextOnColoredBack;
$WPColor-ActionButtonDefaultBack: WPContrastCompliantColor(
	$WPColor-BrandSecondary,
	$_BasicColor-White,
	$WCAG-AAHighContrastRatio,
	false
);
$WPColor-ActionButtonDefaultHoverBack: WPContrastCompliantColor(
	WPDarken($WPColor-ActionButtonDefaultBack, 35%),
	$_BasicColor-White,
	$WCAG-AAHighContrastRatio,
	false
);
$WPColor-ActionButtonPositiveBack: WPContrastCompliantColor(
	$WPColor-PositiveBright,
	$_BasicColor-White,
	$WCAG-AAHighContrastRatio,
	false
);
$WPColor-ActionButtonPositiveHoverBack: WPContrastCompliantColor(
	WPDarken($WPColor-ActionButtonPositiveBack, 15%),
	$_BasicColor-White,
	$WCAG-AAHighContrastRatio,
	false
);
$WPColor-ActionButtonNegativeBack: WPContrastCompliantColor(
	$WPColor-NegativeDim,
	$_BasicColor-White,
	$WCAG-AAHighContrastRatio,
	false
);
$WPColor-ActionButtonNegativeHoverBack: WPContrastCompliantColor(
	WPDarken($WPColor-ActionButtonNegativeBack, 35%),
	$_BasicColor-White,
	$WCAG-AAHighContrastRatio,
	false
);
$WPColor-ActionButtonDisabledFore: $_BasicColor-DarkGray;
$WPColor-ActionButtonDisabledBack: $WPColor-DisabledBack;

$WPColor-BorderedActionButtonPositiveFore: WPContrastCompliantColor(
	$WPColor-TextPositive,
	$_BasicColor-White,
	$WCAG-AAHighContrastRatio,
	false
);
$WPColor-BorderedActionButtonPositiveHoverBack: WPLighten($WPColor-ActionButtonPositiveBack, 90%);
$WPColor-BorderedActionButtonPositiveHoverFore: WPDarken($WPColor-BorderedActionButtonPositiveFore, 15%);
$WPColor-BorderedActionButtonDefaultBack: $_BasicColor-White;
$WPColor-BorderedActionButtonDefaultFore: WPContrastCompliantColor(
	$WPColor-BrandSecondary,
	$_BasicColor-White,
	$WCAG-AAHighContrastRatio,
	false
);
$WPColor-BorderedActionButtonDefaultHoverBack: WPLighten($WPColor-ActionButtonDefaultBack, 85%);
$WPColor-BorderedActionButtonDefaultHoverFore: WPDarken($WPColor-BorderedActionButtonDefaultFore, 15%);
$WPColor-BorderedActionButtonNegativeFore: WPContrastCompliantColor(
	$WPColor-TextNegative,
	$_BasicColor-White,
	$WCAG-AAHighContrastRatio,
	false
);
$WPColor-BorderedActionButtonNegativeHoverBack: WPLighten($WPColor-ActionButtonNegativeBack, 90%);
$WPColor-BorderedActionButtonNegativeHoverFore: WPDarken($WPColor-BorderedActionButtonNegativeFore, 35%);

$WPColor-InformationalWarningBack: #e0f1ff;
$WPColor-NegativeFaint: #ffe8e8;
$WPColor-Error: $WPColor-NegativeBright;
$WPColor-TextPositive: $WPColor-PositiveDim;
$WPColor-PositiveDim: $_BasicColor-DarkGreen;
$WPColor-PositiveFaint: $_BasicColor-VeryVeryLightGreen;

/// TOGGLE BUTTON ///
$WPColor-ToggleButtonUnselectedBack: $_BasicColor-White;
$WPColor-ToggleButtonSelectedBack: $_BasicColor-LightBlue;
$WPColor-ToggleButtonSelectedShadow: rgba($_BasicColor-White, 0.3);
$WPColor-ToggleButtonSelectedInsetShadow: $WPColor-ShadowVeryVerySoft;
$WPColor-ToggleButtonSelectedFore: $WPColor-TextStrong;
$WPColor-ToggleButtonDisabledFore: $WPColor-TextStrong;
$WPColor-PrettyCheckboxDisabledCheckedBack: $_BasicColor-Whitesmoke;
$WPColor-PrettyCheckboxDisabledUncheckedBack: $_BasicColor-LightGainsboro;
$WPColor-PrettyCheckboxDisabledBorder: $_BasicColor-Gainsboro;
$WPColor-PrettyCheckboxUncheckedBack: $_BasicColor-White;
$WPColor-PrettyCheckboxCheckedBack: $WPColor-BrandSecondary;

// --- Dark Mode Colors --- //
// See MyChart color definitions at `\PatientEngagement\MyChartWebServer\Styles\en-US\styles\Packages\bedside-tv\themes\_DarkModePalette.scss`
// Helper to achieve lighter secondary colors
$__TargetLightLuminance: 0.5;
$DarkModeColor-BrandSecondary: WPLightenUntil($WPColor-BrandSecondary, $__TargetLightLuminance);

/// Returns the lightest possible background color in dark mode (which is will be a menu color from a branded pallet).
/// This is the menu background color from the brown pallet
@function WPUtil-DarkmodeLightestBack() {
	@return #413626;
}

// Button colors
$__ActionButtonHoverChange: 15%;
$__ActionButtonHoverBackChange: 22%;
$__ActionButtonBackChange: 70%;
$__BorderedActionButtonBackChange: 70%;
$__SecondaryActionButtonBackChange: 70%;
$__BorderedActionButtonBackHoverChange: 20%;
$DarkModeColor-ActionButtonDefaultBack: $WPColor-BrandSecondary;
$DarkModeColor-ActionButtonDefaultHoverBack: WPDarken(
	$DarkModeColor-ActionButtonDefaultBack,
	$__ActionButtonHoverChange
);
$DarkModeColor-ActionButtonNegativeBack: $WPColor-NegativeDim;
$DarkModeColor-ActionButtonPositiveBack: $WPColor-PositiveBright;
$DarkModeColor-BorderedActionButtonDefaultFore: WPContrastCompliantColor(
	$_BasicColor-White,
	WPUtil-DarkmodeLightestBack(),
	$WCAG-AALargeTextContrastRatio,
	false
);
$DarkModeColor-BorderedActionButtonNegativeFore: WPContrastCompliantColor(
	$_BasicColor-White,
	WPUtil-DarkmodeLightestBack(),
	$WCAG-AALargeTextContrastRatio,
	false
);
$DarkModeColor-NonPrimaryButtonBackground: WPForceRGBA($_BasicColor-Black, 0.3);

// -- Default (Neutral) Button Colors -- //
$DarkModeColor-PrimaryActionButtonDefaultFore: WPContrastCompliantColor(
	$_BasicColor-White,
	WPUtil-DarkmodeLightestBack(),
	$WCAG-AALargeTextContrastRatio,
	false
);
$DarkModeColor-PrimaryActionButtonDefaultBack: WPDarken($WPColor-BrandSecondary, $__ActionButtonBackChange);
$DarkModeColor-PrimaryActionButtonDefaultBorder: WPContrastCompliantColor(
	WPLighten($WPColor-BrandSecondary),
	WPUtil-DarkmodeLightestBack(),
	$WCAG-AALargeTextContrastRatio,
	false
);
$DarkModeColor-NonPrimaryActionButtonDefaultFore: WPContrastCompliantColor(
	WPLighten($WPColor-BrandSecondary),
	WPUtil-DarkmodeLightestBack(),
	$WCAG-AALargeTextContrastRatio,
	false
);
$DarkModeColor-NonPrimaryActionButtonDefaultBorder: WPContrastCompliantColor(
	WPLighten($WPColor-BrandSecondary),
	WPUtil-DarkmodeLightestBack(),
	$WCAG-AALargeTextContrastRatio,
	false
);
$DarkModeColor-NonPrimaryActionButtonDefaultHoverBack: WPDarken(
	$WPColor-BrandSecondary,
	$__SecondaryActionButtonBackChange
);

// -- Negative Button Colors -- //
$DarkModeColor-PrimaryActionButtonNegativeFore: WPContrastCompliantColor(
	$_BasicColor-White,
	WPUtil-DarkmodeLightestBack(),
	$WCAG-AALargeTextContrastRatio,
	false
);
$DarkModeColor-PrimaryActionButtonNegativeBack: WPDarken($WPColor-NegativeDim, $__ActionButtonBackChange);
$DarkModeColor-PrimaryActionButtonNegativeHoverBack: WPDarken(
	$WPColor-NegativeDim,
	$__ActionButtonHoverBackChange
);
$DarkModeColor-PrimaryActionButtonNegativeBorder: WPContrastCompliantColor(
	WPLighten($WPColor-NegativeDim),
	WPUtil-DarkmodeLightestBack(),
	$WCAG-AALargeTextContrastRatio,
	false
);
$DarkModeColor-NonPrimaryActionButtonNegativeFore: WPContrastCompliantColor(
	WPLighten($WPColor-NegativeDim),
	WPUtil-DarkmodeLightestBack(),
	$WCAG-AALargeTextContrastRatio,
	false
);
$DarkModeColor-NonPrimaryActionButtonNegativeBorder: WPContrastCompliantColor(
	WPLighten($WPColor-NegativeDim),
	WPUtil-DarkmodeLightestBack(),
	$WCAG-AALargeTextContrastRatio,
	false
);
$DarkModeColor-NonPrimaryActionButtonNegativeHoverBack: WPDarken(
	$WPColor-NegativeDim,
	$__SecondaryActionButtonBackChange
);

// -- Positive Button Colors -- //
$DarkModeColor-PrimaryActionButtonPositiveFore: WPContrastCompliantColor(
	$_BasicColor-White,
	WPUtil-DarkmodeLightestBack(),
	$WCAG-AALargeTextContrastRatio,
	false
);
$DarkModeColor-PrimaryActionButtonPositiveBack: WPDarken($WPColor-PositiveBright, $__ActionButtonBackChange);
$DarkModeColor-PrimaryActionButtonPositiveHoverBack: WPDarken(
	$WPColor-PositiveBright,
	$__ActionButtonHoverBackChange
);
$DarkModeColor-PrimaryActionButtonPositiveBorder: WPContrastCompliantColor(
	WPLighten($WPColor-PositiveBright),
	WPUtil-DarkmodeLightestBack(),
	$WCAG-AALargeTextContrastRatio,
	false
);
$DarkModeColor-NonPrimaryActionButtonPositiveFore: WPContrastCompliantColor(
	WPLighten($WPColor-PositiveBright),
	WPUtil-DarkmodeLightestBack(),
	$WCAG-AALargeTextContrastRatio,
	false
);
$DarkModeColor-NonPrimaryActionButtonPositiveBorder: WPContrastCompliantColor(
	WPLighten($WPColor-PositiveBright),
	WPUtil-DarkmodeLightestBack(),
	$WCAG-AALargeTextContrastRatio,
	false
);
$DarkModeColor-NonPrimaryActionButtonPositiveHoverBack: WPDarken(
	$WPColor-PositiveBright,
	$__SecondaryActionButtonBackChange
);

$DarkModeColor-ChatBackground: WPDarken($_BasicColor-VeryDarkGray, 30%);
