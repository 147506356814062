// Copyright 2021-2023 Epic Systems Corporation

@use "../../../styles/_colors.scss" as *;
@use "../../../styles/_dimensions.scss" as *;
@use "../../../styles/_utilities.scss" as *;

// Scaling is currently based around having minimum size font when screen width is at or below 600px, but
// will begin scaling up to their max at larger widths. Scaling will stop at a maximum width of 1200px.
$header-font-size-scale: max(0.875rem, min(0.5rem + 0.6667vw, 1.125rem));
$header-font-size-scale-no-text: max(1rem, min(0.75rem + 0.6667vw, 1.2rem));
$text-font-size-scale: max(0.75rem, min(0.5rem + 0.6667vw, 1rem));

// set font size for browsers that that don't support max and min (ios < 11.3, chromium < 79 etc)
// https://developer.mozilla.org/en-US/docs/Web/CSS/max()#browser_compatibility
// modify these according to media window width at the bottom
$header-font-size: 14px;
$header-font-size-no-text: 18px;
$text-font-size: 12px;

$banner-base-color: #19344e;

.banner {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	background-color: $banner-base-color;
	padding: 5px 10px;
	pointer-events: auto;

	font-size: $text-font-size;
	font-size: $text-font-size-scale;

	.textWrapper,
	.iconWrapper {
		display: flex;
	}

	.textWrapper {
		flex-direction: column;
	}

	.iconWrapper {
		align-items: center;
	}

	.icon {
		margin-right: 10px;
		margin-top: 5px;
	}

	.header {
		font-weight: bold;
		font-size: $header-font-size;
		font-size: $header-font-size-scale;
		&.noText {
			font-size: $header-font-size-no-text;
			font-size: $header-font-size-scale-no-text;
		}
		color: #fff;
	}

	.text {
		color: #fff;
	}

	.buttonsWrapper {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-left: 20px;

		& > *:not(:first-child) {
			margin-left: 5px;
		}
	}
}

@media (max-width: $screen-width-inflection) {
	.banner {
		padding: 2px 10px;

		.buttonsWrapper {
			margin-left: 5px;
		}
	}
}

// resizing for non-max/min larger browsers
// use a piece-meal / step-wise approach to approximate our scaling over a few different screen-sizes
@supports not (font-size: $header-font-size-scale) {
	// enforce minimums below below 800px
	@media only screen and (max-width: 800px) {
		.banner.header {
			font-size: 14px;
			&.noText {
				font-size: 18px;
			}
		}

		.banner.text {
			font-size: 12px;
		}
	}

	// use scaled value of 800px from (800px to 1000px)
	@media only screen and (max-width: 1000px) {
		.banner.header {
			font-size: 15.333px;
			&.noText {
				font-size: 19.333px;
			}
		}

		.banner.text {
			font-size: 13.333px;
		}
	}

	// use scaled value of 1000px from (1000px to 1200px)
	@media only screen and (max-width: 1200px) {
		.banner.header {
			font-size: 16.667px;
			&.noText {
				font-size: 20.667px;
			}
		}

		.banner.text {
			font-size: 14.667px;
		}
	}

	// allow maximums above 1200px
	@media only screen and (min-width: 1200px) {
		.banner.header {
			font-size: 18px;
			&.noText {
				font-size: 22px;
			}
		}

		.banner.text {
			font-size: 16px;
		}
	}
}
