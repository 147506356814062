// Copyright 2021 Epic Systems Corporation

@import "../../styles/_colors.scss";

// the pinning icon is used in two scenarios: participant name bar & participant row in the participant list
// these two scenarios rely on :hover effects to change the state of the icon, but the :hover that is relied
// upon is on a parent element with differing levels of depth between the two scenarios
// to avoid messy CSS needing to deal with the varying code structures, these mixins can be used to create
// the pin icons in any location that needs them

// sizing for the active pin icon, used when pinned and when hovered
@mixin activePinIcon($size, $size-scale) {
	// Always show the icon when it's pinned
	width: $size;
	min-width: $size;

	width: $size-scale;
	min-width: $size-scale;
	opacity: 1;
}

// basic pinning icon, see ParticipantRow.module.scss on how to include
// $pinned-selector and $mobile-selector aren't strictly necessary, but allows better
// transparency to ensure the selectors match if used elsewhere
@mixin pinningIcon($size, $size-scale, $pinned-selector, $mobile-selector) {
	height: $size;
	height: $size-scale;

	overflow: hidden;
	transition: 0.25s;

	// Screen readers read the button more consistently if it has at least 1px width
	// Opacity needs to be non-zero so that the pin button can be focused on mobile Firefox when the user is unpinned
	width: 1px;
	opacity: 0.001;

	// must specify min-width or button size can get weird with long names
	min-width: 0px;

	&.#{$mobile-selector} {
		// Set max width to (hopefully) fix iOS display issues
		max-width: 1px;
	}

	// The pin icon rotates from left-facing to down-facing when pinned
	svg {
		transition: 0.45s;
		transform: rotate(90deg) translate(-1px, 1px);
	}

	&.#{$pinned-selector} svg {
		transform: rotate(0deg);
	}

	&.#{$pinned-selector} {
		// Always show the icon when it's pinned
		@include activePinIcon($size, $size-scale);

		&.mobile {
			max-width: none;
		}

		svg * {
			fill: $WPColor-BrandAccentBack;
		}
	}
}
