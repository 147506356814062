// Copyright 2020 Epic Systems Corporation

@use "../../styles/_dimensions.scss" as *;
@use "../../styles/_utilities.scss" as *;

$button-transition-duration: 0.25s;

// Mixin to set the video element/placeholder to the desired size
// Since actual video has variable aspect ratio, we only set height, and let it take the appropriate width on its own
// The placeholder needs both height and width set
@mixin videoSizes($height) {
	video {
		max-height: $height;
		// This fixes an iOS 17 bug where the video preview is really skinny
		max-width: $height * 1.9;
	}
	&.noVideo .videoPlaceholder {
		height: $height;
		width: $height * $float-video-placeholder-width-factor;
	}
}

.videoPreview {
	position: absolute;
	bottom: 0;
	right: 0;
	margin: 0 17px 10px 0; // 17px is the expected max scrollbar width. See: https://codepen.io/sambible/post/browser-scrollbar-widths
	z-index: 30;
	border-radius: 10px;

	display: flex;
	overflow: hidden;

	@include videoSizes(($float-video-height-1));

	video {
		border-radius: 10px;

		// iOS cuts off part of the video unless we force width to be unset
		width: unset !important;
	}

	&.noVideo {
		background-color: #000;
		.videoPlaceholder {
			display: flex;
		}
	}
}

.videoPreview,
.videoPreview video,
.videoPlaceholder {
	transition: height $button-transition-duration, width $button-transition-duration;
}

.togglePreviewSizeButton {
	transition: top $button-transition-duration, left $button-transition-duration,
		height $button-transition-duration, width $button-transition-duration,
		border-color $button-transition-duration;
}

.togglePreviewSizeButton {
	position: absolute;
	height: 25px;
	width: 25px;
	max-width: 100%;
	z-index: 10;
	top: 5px;
	left: 5px;
	padding: 0;
	background-color: #444;
	background-color: rgba(0, 0, 0, 0.7);
	border: #444 solid 1px;
	border-color: rgba(0, 0, 0, 0.7);
	border-radius: 50px;

	&:not(:disabled) {
		cursor: pointer;
	}

	@include focusedOrHovered() {
		background-color: #888;
		background-color: rgba(0, 0, 0, 0.5);
		border-color: white;
		outline: none;
	}
}

.videoPreview.hidden {
	transition: 0s;
	opacity: 0;
}

.toggleButtonInner {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	outline: none;
	transform: scaleX(1#{"/*!rtl:scaleX(-1)*/"});
}

.videoPreview.minimized {
	@include videoSizes(($float-video-height-minimized));

	.togglePreviewSizeButton {
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: none;
		border-radius: inherit;
	}

	@include focusedOrHovered(".togglePreviewSizeButton") {
		& ~ video {
			height: $float-video-height-minimized-peeking;
		}
		& ~ .videoPlaceholder {
			height: $float-video-height-minimized-peeking;
			width: $float-video-height-minimized-peeking * $float-video-placeholder-width-factor;
		}
	}

	.toggleButtonInner {
		transform: scaleY(-1#{"/*!rtl:scaleY(-1)*/"}) scaleX(-1);
	}
}

// Scale the video at smaller screen sizes
.videoPreview {
	// The card sometimes overlaps with the preview at this point
	// With the card centered, there's padding on the left of the card equal to the size of the preview
	// So these first two account for double the width of the floating video

	$overlapWidth-1: $card-width-large + (2 * $float-video-width-max-1);
	$overlapHeight-1: 1024px; // Special value chosen to account for iPads + iOS specific warnings
	@media only screen and (max-width: $overlapWidth-1) and (max-height: $overlapHeight-1) {
		@include videoSizes(($float-video-height-2));
	}

	$overlapWidth-2: $card-width-large + (2 * $float-video-width-max-2);
	$overlapHeight-2: $card-height-est + $header-height + $float-video-height-2 + 10px;
	@media only screen and (max-width: $overlapWidth-2) and (max-height: $overlapHeight-2) {
		@include videoSizes(($float-video-height-3));
	}

	// At certain sizes, the card narrows and shifts left from center
	// So at the point of overlap, there's no padding on the left
	$overlapWidth-3: $card-width-small + $float-video-width-max-3;
	$overlapHeight-3: $card-height-est + $header-height + $float-video-height-3 + 10px;
	@media only screen and (max-width: $overlapWidth-3) and (max-height: $overlapHeight-3) {
		@include videoSizes(($float-video-height-4));
	}

	// At even smaller sizes, overlap is possible again, but reducing the preview size at that point would render it pretty useless
}
