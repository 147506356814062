// Copyright 2020 Epic Systems Corporation

.warningRow {
	display: flex;
	align-items: center;
	background: #ffc000;
	width: calc(100% + 10px);
	border: none;
	margin-left: -5px;
	padding: 6px;
	cursor: pointer;

	svg {
		fill: #000000;

		&.defaultIcon {
			background-color: #000000;
			fill: #ffc000;
			border-radius: 100%;
		}
	}
}

.chevron {
	transition: 0.4s ease;

	path {
		stroke: #000;
	}
}

.warningRow:not(:first-child) {
	border-top: #888 solid 1px;
}
