// Copyright 2021 Epic Systems Corporation

@use "../../styles/_utilities.scss" as *;
@use "../../styles/_colors.scss" as *;

.banner {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	padding: 15px 10px;
	border: 1px solid $WPColor-BorderVerySubtle;
	border-radius: 0.25rem;
	margin-top: 0px;
	margin-bottom: 5px;
	color: $WPColor-TextBody;

	&.inline {
		margin: 10px 0;
		padding: 5px;
		align-items: flex-start;
		text-align: start;
		font-size: 1rem;

		.buttonWrapper {
			align-self: center;
		}
	}

	.bannerIcon {
		height: 24px;
		width: 24px;
	}

	&.success {
		background-color: $WPColor-PositiveFaint;

		.title {
			color: $WPColor-TextPositive;
		}

		.bannerIcon {
			fill: $WPColor-TextPositive;
		}
	}

	&.info {
		background-color: $WPColor-InformationalWarningBack;

		.title {
			color: $WPColor-BrandPrimary;
		}
	}

	&.warning {
		background-color: $WPColor-VerySoftWarningBack;

		.bannerIcon {
			fill: #daa520; // Goldenrod
		}
	}

	&.critical {
		background-color: $WPColor-NegativeFaint;

		.title {
			color: $WPColor-Error;
		}

		.bannerIcon {
			fill: $WPColor-Error;
		}
	}

	// for medium sized screens put a small gap at the top
	@media only screen and (min-height: 650px) {
		& {
			margin-top: 5px;
		}

		// for iPad and larger (devices that can fit the whole card and banner without scrolling)
		// add a top spacing based on the size of the screen
		@media (min-height: 850px) {
			& {
				margin-top: 5vh;
			}
		}
	}
}

.title {
	font-size: 20px;
}

.iconAndTextWrapper {
	display: flex;

	&:not(:last-child) {
		margin-bottom: 5px;
	}
}

.iconWrapper {
	margin-right: 7px;
}

.textWrapper {
	display: flex;
	flex-direction: column;

	span + span {
		padding-right: 31px;
		word-break: break-word;
	}
}
