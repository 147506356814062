// Copyright 2020-2022 Epic Systems Corporation

// only apply the hover effect to devices with hover-capable input (mouses)
// focus effects are always applied
@mixin focusedOrHovered($selector: "&") {
	#{$selector}:focus,
	#{$selector}:active {
		@content;
	}

	@media (pointer: fine) {
		#{$selector}:hover {
			@content;
		}
	}
}

// only apply the effects to devices with fine-input pointers (mouses)
// we are checking pointer instead of hover because certain android devices support hovering
@mixin focusedOrHoveredNotMobile($selector: "&") {
	@media (pointer: fine) {
		#{$selector}:focus {
			@content;
		}

		#{$selector}:hover {
			@content;
		}
	}
}

// only apply hover to devices with fine-input pointers (mouse)
// we are checking pointer instead of hover because certain android devices support hovering
@mixin hoveredNotMobile($selector: "&") {
	@media (pointer: fine) {
		#{$selector}:hover {
			@content;
		}
	}
}
