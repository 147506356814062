// Copyright 2020 Epic Systems Corporation

.spinner {
	width: 60px;
	height: 60px;
	border: 10px solid #d2d2da;
	border-top-color: transparent;
	border-radius: 50%;
	position: relative;
	animation: spin 1s linear infinite;
	color: transparent;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@media (prefers-reduced-motion) {
	.spinner {
		animation: none;
	}
}
