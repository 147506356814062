// Copyright 2021 Epic Systems Corporation

@import "../../styles/_colors.scss";

.displayNameInputRow {
	display: flex;
	flex-direction: column;
	padding-top: 18px;
	font-size: 16px;

	.displayNameInput {
		color: $WPColor-TextBody;
		max-width: 100%;
		max-width: 100%;
		padding: 4px 12px;
		border-radius: 4px;
		border: 1px solid currentColor;
		line-height: 1.5rem; // Match action button height
	}

	.displayNameInputLabel {
		text-align: left;
		margin-bottom: 6px;
	}
}
