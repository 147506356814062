// Copyright 2021 Epic Systems Corporation

$top-ratio: 26.667;
$left-ratio: 2.162;
$width-ratio: 13.333;
$height-ratio: 4.444;
$origin-ratio: 2;

@mixin spinnerIcon($spinner-size) {
	width: $spinner-size;
	height: $spinner-size;

	$origin: calc($spinner-size / $origin-ratio);

	transform: scaleX(1#{"/*!rtl:scaleX(-1)*/"});

	div {
		transform-origin: $origin $origin #{"/*!rtl:-#{$origin}, #{$origin}*/"};
		animation: spinnerIconAnimation 1.2s linear infinite;
	}
	div:after {
		content: " ";
		display: block;
		position: absolute;
		top: calc($spinner-size / $top-ratio);
		left: calc($spinner-size / $left-ratio);
		width: calc($spinner-size / $width-ratio);
		height: calc($spinner-size / $height-ratio);
		border-radius: 20%;
		background: gray;
	}
	div:nth-child(1) {
		transform: rotate(0deg) #{"/*!rtl:translate(-#{$origin}, #{$origin}) rotate(0deg) translate(#{$origin}, -#{$origin})*/"};
		animation-delay: -1.1s;
	}
	div:nth-child(2) {
		transform: rotate(30deg) #{"/*!rtl:translate(-#{$origin}, #{$origin}) rotate(30deg) translate(#{$origin}, -#{$origin})*/"};
		animation-delay: -1s;
	}
	div:nth-child(3) {
		transform: rotate(60deg) #{"/*!rtl:translate(-#{$origin}, #{$origin}) rotate(60deg) translate(#{$origin}, -#{$origin})*/"};
		animation-delay: -0.9s;
	}
	div:nth-child(4) {
		transform: rotate(90deg) #{"/*!rtl:translate(-#{$origin}, #{$origin}) rotate(90deg) translate(#{$origin}, -#{$origin})*/"};
		animation-delay: -0.8s;
	}
	div:nth-child(5) {
		transform: rotate(120deg) #{"/*!rtl:translate(-#{$origin}, #{$origin}) rotate(120deg) translate(#{$origin}, -#{$origin})*/"};
		animation-delay: -0.7s;
	}
	div:nth-child(6) {
		transform: rotate(150deg) #{"/*!rtl:translate(-#{$origin}, #{$origin}) rotate(150deg) translate(#{$origin}, -#{$origin})*/"};
		animation-delay: -0.6s;
	}
	div:nth-child(7) {
		transform: rotate(180deg) #{"/*!rtl:translate(-#{$origin}, #{$origin}) rotate(180deg) translate(#{$origin}, -#{$origin})*/"};
		animation-delay: -0.5s;
	}
	div:nth-child(8) {
		transform: rotate(210deg) #{"/*!rtl:translate(-#{$origin}, #{$origin}) rotate(210deg) translate(#{$origin}, -#{$origin})*/"};
		animation-delay: -0.4s;
	}
	div:nth-child(9) {
		transform: rotate(240deg) #{"/*!rtl:translate(-#{$origin}, #{$origin}) rotate(240deg) translate(#{$origin}, -#{$origin})*/"};
		animation-delay: -0.3s;
	}
	div:nth-child(10) {
		transform: rotate(270deg) #{"/*!rtl:translate(-#{$origin}, #{$origin}) rotate(270deg) translate(#{$origin}, -#{$origin})*/"};
		animation-delay: -0.2s;
	}
	div:nth-child(11) {
		transform: rotate(300deg) #{"/*!rtl:translate(-#{$origin}, #{$origin}) rotate(300deg) translate(#{$origin}, -#{$origin})*/"};
		animation-delay: -0.1s;
	}
	div:nth-child(12) {
		transform: rotate(330deg) #{"/*!rtl:translate(-#{$origin}, #{$origin}) rotate(330deg) translate(#{$origin}, -#{$origin})*/"};
		animation-delay: 0s;
	}
}

.spinnerIconSmall {
	@include spinnerIcon(18px);
}

.spinnerIconLarge {
	@include spinnerIcon(30px);
}

@keyframes spinnerIconAnimation {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
