// Copyright 2021 Epic Systems Corporation

.carousel {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
}

.list {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
