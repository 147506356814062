// Copyright 2021 Epic Systems Corporation

@use "../../styles/_dimensions.scss" as *;

.participantView {
	display: flex;
	flex-direction: row;
	height: 100%;
	width: 100%;
	flex-grow: 1;
}

main {
	height: 100%;
}

// Traditional "horizontal" view for the participant strip. The strip is beneath the main feed rather than beside it.
@media only screen and (max-width: $screen-width-inflection) and (min-height: $minimum-screen-size-for-header) and (orientation: portrait) {
	.participantView {
		flex-direction: column;
	}
}

//For cases where the device is both skinny enough in portrait to trigger the horizontal participant strip and reduce the size of the header, properly account for the header's size
@media only screen and (max-width: $screen-width-inflection) and (max-height: $minimum-screen-size-for-header) and (orientation: portrait) {
	.participantView {
		flex-direction: column;
	}
}

//Check special cases of the banner being at extra small heights
@media only screen and (max-width: 272px) {
	.participantView {
		flex-direction: column;
	}

	//Controls wrap to two lines at this point, increase margin to deal with extra height
	@media (max-width: 169px) {
		.participantView {
			flex-direction: column;
		}
	}
}
