// Copyright 2021 Epic Systems Corporation

@import "../../styles/_colors.scss";
@import "../../styles/theme/colorUtils.scss";
@import "../../styles/_utilities.scss";
@import "../../styles/_dimensions.scss";

.noneOptionRow {
	display: flex;
	justify-content: center;
	margin: 0 0 5px;
}

.noneOption {
	border-radius: 10px;
	border: 4px solid $EVCColor-MenuBack;
	width: $background-none-width;
	background-color: $menu-border-color;

	@include focusedOrHovered() {
		border-color: WPDarken($toggle-gray, 20%);
	}

	&.selected {
		border-color: $WPColor-BrandAccentBack;
	}

	@media (max-width: $small-screen-width-inflection) {
		width: $background-none-width-small;
	}
}
