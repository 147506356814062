// Copyright 2022 Epic Systems Corporation

@import "../../../styles/_dimensions.scss";
@import "../../../styles/_colors.scss";

.surveyQuestions {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;

	margin-bottom: 20px;

	// Don't style the survey questions list or child lists in this workflow
	ul,
	& {
		list-style: none;
		padding: 0;
		width: 100%;
	}

	> li {
		margin: 10px;
		display: inline-block;
	}

	@media screen and (max-width: $screen-width-inflection) {
		display: block; // Use display block on mobile to avoid bad interactions with choice buttons
		margin-bottom: 5px;

		.groupListItem:not(:last-child) {
			border-bottom: 1px solid;
			border-color: #dbdbdb;
		}

		.questionGroup {
			width: 100%;
			margin: 3px 0;
		}
		.groupListItem {
			width: 100%;
		}
	}
	.groupListItem {
		display: flex;
		flex: 1;
		min-width: 260px;
	}
	.questionGroup {
		border: 0px;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-content: flex-start;
		align-items: flex-start;
		margin: 0;
	}
}
