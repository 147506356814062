﻿// Copyright 2022 Epic Systems Corporation

@use "../../styles/_colors" as *;
@use "../../styles/_dimensions.scss" as *;

// MIXINS

@mixin WPCoreStyle-ToggleButtonSizeAndPosition {
	display: inline-block;
	margin: 0 0.25rem 0.5rem 0;
	position: relative;
	width: max-content;

	&.wide {
		width: 100%;
	}

	&.hardwareTest {
		margin: 0 0.1rem 0.5rem;
	}
}

@mixin WPCoreStyle-ToggleButtonContainer {
	@include WPCoreStyle-ToggleButtonSizeAndPosition;
	min-width: 2.75rem;
	text-align: center;
	vertical-align: top;
}

@mixin WPCoreStyle-ButtonCheckContainer {
	@include WPCoreStyle-ToggleButtonSizeAndPosition;
	box-shadow: none;
	color: inherit;
	padding: 0;
	white-space: normal;
}

@mixin WPCoreStyle-ToggleButtonBase {
	background-color: $WPColor-ToggleButtonUnselectedBack;
	&.isInDarkMode {
		background-color: $EVCColor-MenuBack;
	}
	border-radius: 0.125rem;
	box-shadow: 0 0 4px $WPColor-ShadowVerySoft; // Depart from standard MyC style to accommodate white background
	cursor: pointer;
	display: flex;
	align-items: center;
	flex-direction: column;
	line-height: 1.5rem;
	position: relative;
	padding: 0.25rem 0.75rem;
	vertical-align: top;
	transition-duration: 0.1s;
	transition-property: top, box-shadow;

	&.hasIcon {
		border-radius: 10px;
	}

	svg {
		fill: $WPColor-BrandTertiary;
	}
}

@mixin WPCoreStyle-ButtonCheckBase {
	font-size: 1rem;
	margin: 0;
	text-align: left;
	width: 100%;
	align-items: flex-start;
	span.labelText {
		display: flex;
		user-select: none;
		-webkit-user-select: none;
	}
	.labelText::before {
		flex-shrink: 0;
		background-attachment: scroll;
		background-color: $WPColor-PrettyCheckboxUncheckedBack;
		background-image: none;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: contain;
		border: 1px solid $WPColor-BorderSubtle;
		border-radius: 2px;
		box-shadow: inset 0px 1px 1px $WPColor-ShadowSoft;
		content: "";
		display: inline-block;
		height: 0.9rem;
		line-height: 0.85rem;
		margin: 0.25rem 0.3rem -0.1rem 0;
		position: relative;
		text-indent: 0;
		vertical-align: top;
		width: 0.9rem;
	}
}

@mixin WPCoreStyle-ToggleButtonChecked {
	background-color: $WPColor-ToggleButtonSelectedBack;
	&.isInDarkMode {
		color: $EVCColor-HeaderButtonFore;
		background-color: $EVCColor-MenuButtonHoverBack;
	}
	box-shadow: 0 1px 0 $WPColor-ToggleButtonSelectedShadow,
		inset 0 2px 1px $WPColor-ToggleButtonSelectedInsetShadow;
	color: $WPColor-ToggleButtonSelectedFore;
	top: 1px;

	svg {
		fill: $WPColor-BrandPrimary;
	}
}

@mixin WPCoreStyle-ButtonCheckChecked {
	.labelText::before {
		background-color: $WPColor-PrettyCheckboxCheckedBack;
		@include WPUtil-Base64BgImg("checkMarkWhite");
		border-color: $WPColor-PrettyCheckboxCheckedBack;
	}
}

@mixin WPCoreStyle-ToggleButtonCheckedHoverFocus {
	box-shadow: 0 1px 0 $WPColor-ToggleButtonSelectedShadow,
		inset 0 2px 3px $WPColor-ToggleButtonSelectedInsetShadow;
}

@mixin WPCoreStyle-ToggleButtonHoverFocus {
	box-shadow: 0 0 4px 1px $WPColor-ShadowSoft; // Depart from standard MyC style to accommodate white background
	color: $WPColor-ToggleButtonSelectedFore;
	&.isInDarkMode {
		color: $EVCColor-HeaderButtonFore;
		background-color: $EVCColor-MenuButtonHoverBack;
	}
	text-decoration: underline;
}

@mixin WPCoreStyle-ButtonCheckFocus {
	&::before {
		outline: 1px dotted $WPColor-BorderVeryDark;
		outline: 5px auto -webkit-focus-ring-color;
	}
}

@mixin WPCoreStyle-ButtonCheckDisabledUnchecked {
	color: $WPColor-ToggleButtonDisabledFore;
	cursor: default;
	font-weight: normal;
	text-decoration: none;

	&,
	&::before {
		background-color: $WPColor-PrettyCheckboxDisabledUncheckedBack;
		border-color: $WPColor-PrettyCheckboxDisabledBorder;
	}
}

@mixin WPCoreStyle-ButtonCheckDisabledChecked {
	&,
	&::before {
		background-color: $WPColor-PrettyCheckboxDisabledCheckedBack;
	}

	&::before {
		@include WPUtil-Base64BgImg("checkMarkGray");
	}
}

@mixin WPCoreStyle-ToggleButtonDisabledUnchosen {
	color: $WPColor-ToggleButtonDisabledFore;
	cursor: default;
	font-weight: normal;
	text-decoration: none;
	background-color: $WPColor-PrettyCheckboxDisabledUncheckedBack;
	border-color: $WPColor-PrettyCheckboxDisabledBorder;
}

@mixin WPCoreStyle-ToggleButtonDisabledChosen {
	background-color: $WPColor-PrettyCheckboxDisabledCheckedBack;
}

@mixin WPCoreStyle-ToggleButtonChosenIcon {
	box-shadow: inset 0 0 0 0.1rem $WPColor-ShadowWhite, 0 0 0 0.05rem $WPColor-ShadowWhite;
	border-radius: 1rem;
	top: 1px;
}

@mixin WPCoreStyle-ToggleButtonDisabledChosenIcon {
	box-shadow: none;
}

// The container for the input, label, and icon will grow on smaller screen sizes
@mixin WPCoreStyle-ToggleButtonWithIcon {
	@media screen and (max-width: $small-screen-width-inflection) {
		flex-grow: 1;
	}
}

// The label containing the icon and text will grow on smaller screen sizes
@mixin WPCoreStyle-ToggleButtonLabelWithIcon {
	@media screen and (max-width: $small-screen-width-inflection) {
		flex-grow: 1;
	}
}

// The icon is repositioned to be centered between the edge of the label and the text itself by adjusting the margins
// The icon is vertically centered using vertical-align
@mixin WPCoreStyle-ToggleButtonIconBase {
	margin-left: -0.25rem;
	margin-right: 0.25rem;
	vertical-align: middle;
}

/// Consolidates the definitions for Base64-encoded images. This pattern should not be used moving forward,
/// but this mixin facilitates retiring these in one go in the future.
@mixin WPUtil-Base64BgImg($description: null) {
	$imgUrl: null !default;

	@if $description == "checkMarkGray" {
		$imgUrl: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAMAAAAM7l6QAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4AIYEwk7S1TSdgAAAEhQTFRFAAAAWFhYWFhYWFhYWFhYWFhYWFhYWFhYWFhYWFhYWFhYWFhYWFhYWFhYWFhYWFhYWFhYWFhYWFhYWFhYWFhYWFhYWFhY////Fo82tgAAABZ0Uk5TAAQTFBUqKzM4OWFi0dLT1Nrp7vD9/tCeZTMAAAABYktHRBcL1piPAAAAcUlEQVQoz83S0Q5AMAwF0I1iDGOb/v+nSmoTia4PJKKPPS+9zVXqj6NFhdFKOmO0kmLZSRF9LWnoP1At6jCBpBEdSIrZ71p52pBzN5uQHRyXKHvj+LzJt0PN7RPJC3pxVk8vaPKikq+d0C6ztC+a+2R2erQPOW3XocEAAAAASUVORK5CYII=");
	} @else if $description == "checkMarkWhite" {
		$imgUrl: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4AIYEjQPAcoESgAAAKVJREFUSMft1rENgzAQQFGKbAAsEUoaZxyycYCKDWhQFoCfIk4Tgm2k8yEF//70Gp91WZZKCQRcjkBL4AHctdGOd7MK/oWigm+gnyag0EafwC2hx6J7l10KbYAWKLXR2Q71PjwGig+XQnO71L9a4aKvFzB2yIlbtBddmQD8Ko4G4osDNRKfgQuPg+7EZdFAPA7qweOiG/gI1Jq3kwEGoDrHifpXvQANql+AlGeZnQAAAABJRU5ErkJggg==");
	}

	@if $description {
		background-image: $imgUrl;
	}
}

// STYLE STRUCTURE

.toggleButton {
	@include WPCoreStyle-ToggleButtonContainer;

	&.hasIcon {
		@include WPCoreStyle-ToggleButtonWithIcon;

		._dataEntryLabel {
			@include WPCoreStyle-ToggleButtonLabelWithIcon;

			._Icon {
				@include WPCoreStyle-ToggleButtonIconBase;
			}
		}
	}
}
.buttonCheck {
	@include WPCoreStyle-ButtonCheckContainer;
}

._input {
	&.toggleButton {
		left: auto;
		opacity: 0;
		position: absolute;
		top: auto;
		width: auto;
		z-index: -1;
		display: inline-block;
		margin: 0;
	}
	&.buttonCheck {
		display: inline-block;
		font-size: 0;
		margin: 0;
		opacity: 0;
		position: absolute;
		top: 0;
		width: 1px;
	}
}

._dataEntryLabel {
	@include WPCoreStyle-ToggleButtonBase;

	&.buttonCheck {
		@include WPCoreStyle-ButtonCheckBase;
	}
}

._input:checked {
	+ ._dataEntryLabel {
		@include WPCoreStyle-ToggleButtonChecked;

		&.buttonCheck {
			@include WPCoreStyle-ButtonCheckChecked;
		}
		&.toggleButton {
			._Icon {
				@include WPCoreStyle-ToggleButtonChosenIcon;
			}
		}
	}

	+ ._dataEntryLabel:hover,
	&:focus + ._dataEntryLabel {
		@include WPCoreStyle-ToggleButtonCheckedHoverFocus;

		&.toggleButton {
			._Icon {
				@include WPCoreStyle-ToggleButtonChosenIcon;
			}
		}
	}
}

._input ~ ._dataEntryLabel:hover {
	@include WPCoreStyle-ToggleButtonHoverFocus;
}

._input:focus + ._dataEntryLabel {
	@include WPCoreStyle-ToggleButtonHoverFocus;

	&.buttonCheck {
		@include WPCoreStyle-ButtonCheckFocus;
	}
}

._input:disabled {
	+ ._dataEntryLabel {
		&.buttonCheck {
			@include WPCoreStyle-ButtonCheckDisabledUnchecked;
		}
		@include WPCoreStyle-ToggleButtonDisabledUnchosen;
	}

	&:checked + ._dataEntryLabel {
		&.buttonCheck {
			@include WPCoreStyle-ButtonCheckDisabledChecked;
		}

		@include WPCoreStyle-ToggleButtonDisabledChosen;

		._Icon {
			@include WPCoreStyle-ToggleButtonDisabledChosenIcon;
		}
	}
}
