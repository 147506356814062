// Copyright 2020 Epic Systems Corporation

@import "../../../styles/_colors.scss";
@import "../../../styles/_utilities.scss";
@import "../../../styles/theme/colorUtils.scss";

.wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	cursor: pointer;
	width: 45px;
	height: max-content;
	aspect-ratio: 45/24;
	border-radius: 1024px;
	background-color: $toggle-gray;
	border: 2px solid $toggle-gray;
	padding: 0;

	@include focusedOrHovered() {
		outline: none;
		background-color: WPDarken($toggle-gray, 10%);
		border-color: WPDarken($toggle-gray, 20%);
	}

	&.on {
		background-color: $toggle-green;
		border-color: $toggle-green;

		@include focusedOrHovered() {
			background-color: WPDarken($toggle-green, 10%);
			border-color: WPDarken($toggle-green, 20%);
		}
	}
}

.slider {
	position: absolute;
	cursor: pointer;
	height: 90%;
	aspect-ratio: 1 / 1;
	background-color: #ffffff;
	border-radius: 50%;
	top: 5%;
	left: 2.5%;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	box-shadow: -1px 1px 5px #444444;

	.wrapper.on & {
		fill: #f6fff7;
		// Translation by percentage won't be perfect at very different scales: the current value is
		// optimized for the default 45px width, and becomes less precise at much smaller or larger widths
		-webkit-transform: translateX(115%);
		transform: translateX(115%);
		box-shadow: -1px 1px 5px #676767;
	}
}

.onIcon {
	position: absolute;
	opacity: 1;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	height: 100%;

	background-color: transparent;
	fill: #ffffff;

	left: 0;
	top: 0;

	.wrapper:not(.on) & {
		opacity: 0;
	}
}
