// Copyright 2022 Epic Systems Corporation

.waitingRoomWrapper {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.waitingRoomEmbed {
	width: 100%;
	height: 100%;

	&.hidden {
		display: none;
	}
}
