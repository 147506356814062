// Copyright 2022 Epic Systems Corporation

/// DEFAULT VALUES ///

$_Default-BrandPrimary: #246fb5;
$_Default-BrandAccentBack: #00a1db;
$_Default-BrandBackground: #f2f7fb;

$_Default-HeaderBack: #000000;
$_Default-HeaderButtonFore: #ffffff;
$_Default-HeaderButtonBack: $_Default-HeaderBack;
$_Default-HeaderButtonHoverBack: #111111;
$_Default-HeaderButtonHoverBorder: #333333;
$_Default-MenuBack: #222222;
$_Default-MenuButtonBack: $_Default-MenuBack;
$_Default-MenuButtonHoverBack: #333333;
$_Default-MenuButtonHoverBorder: #444444;

/// FUNCTION TO SET BRANDED VARIABLES ///

@mixin addBrandingDefaultVariables() {
	:root {
		--WPColor-BrandPrimary: #{$_Default-BrandPrimary};
		--WPColor-BrandAccentBack: #{$_Default-BrandAccentBack};
		--WPColor-BrandBackground: #{$_Default-BrandBackground};

		--EVCColor-HeaderBack: #{$_Default-HeaderBack};
		--EVCColor-HeaderButtonFore: #{$_Default-HeaderButtonFore};
		--EVCColor-HeaderButtonBack: #{$_Default-HeaderButtonBack};
		--EVCColor-HeaderButtonHoverBack: #{$_Default-HeaderButtonHoverBack};
		--EVCColor-HeaderButtonHoverBorder: #{$_Default-HeaderButtonHoverBorder};
		--EVCColor-MenuBack: #{$_Default-MenuBack};
		--EVCColor-MenuButtonBack: #{$_Default-MenuButtonBack};
		--EVCColor-MenuButtonHoverBack: #{$_Default-MenuButtonHoverBack};
		--EVCColor-MenuButtonHoverBorder: #{$_Default-MenuButtonHoverBorder};
	}
}

/// BRANDING COLORS ///

$WPColor-BrandPrimary: var(--WPColor-BrandPrimary);
$WPColor-BrandAccentBack: var(--WPColor-BrandAccentBack);
$WPColor-BrandBackground: var(--WPColor-BrandBackground);

$EVCColor-HeaderBack: var(--EVCColor-HeaderBack);
$EVCColor-HeaderButtonFore: var(--EVCColor-HeaderButtonFore);
$EVCColor-HeaderButtonBack: var(--EVCColor-HeaderButtonBack);
$EVCColor-HeaderButtonHoverBack: var(--EVCColor-HeaderButtonHoverBack);
$EVCColor-HeaderButtonHoverBorder: var(--EVCColor-HeaderButtonHoverBorder);
$EVCColor-MenuBack: var(--EVCColor-MenuBack);
$EVCColor-MenuButtonBack: var(--EVCColor-MenuButtonBack);
$EVCColor-MenuButtonHoverBack: var(--EVCColor-MenuButtonHoverBack);
$EVCColor-MenuButtonHoverBorder: var(--EVCColor-MenuButtonHoverBorder);
