// Copyright 2021 Epic Systems Corporation

@import "../../../styles/_colors.scss";
@import "../../../styles/_utilities.scss";

.deviceSelector {
	color: $EVCColor-SelectorFore;
	fill: $EVCColor-SelectorFore;
	background: none;
	border: 1px solid currentColor;
	// Width and height are manually set/calculated with box-sizing: content-box to match action button
	// This is because line-height property is not supported for select elements, and so to make the element height based on text height,
	// the height property must be set directly.
	box-sizing: content-box;
	// Set width to 100% but leave room for 1px border on each side and 4px padding
	width: calc(100% - 2px - 8px);
	padding: 4px; // Add 4px padding to each side
	height: 1.5rem; // Because content-box sizing is used, extra padding will be added above/below the height, but separate from the border
	border-radius: 5px;
	cursor: pointer;

	@include focusedOrHovered() {
		background: $EVCColor-SelectorBackgroundSelected;
		outline: none;
	}

	&.dark {
		color: $EVCColor-SelectorForeOnColoredBack;
		fill: $EVCColor-SelectorForeOnColoredBack;
		border: 1px solid $EVCColor-MenuBack;

		@include focusedOrHovered() {
			background: $EVCColor-MenuButtonHoverBack;
			border-color: $EVCColor-MenuButtonHoverBorder;
		}
	}
}

.deviceSelectorOptionContainer {
	text-align: left;
}

.deviceSelectorSpan {
	font-weight: bold;
	margin: 0 5px;
	text-align: left;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
