// Copyright 2021 Epic Systems Corporation

@use "../../../styles/_colors.scss" as *;
@use "../../../styles/_dimensions.scss" as *;

.iconWrapper {
	align-items: center;
	justify-content: center;
	margin: auto;
	display: flex;
	width: inherit;
	height: inherit;

	svg > * {
		fill: #ffffff;
	}

	& ~ div {
		display: none;
	}
}

.placeholderText {
	color: $placeholder-video-text;
	font-size: 24px;
	padding: 0px 5px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: pre-line;
}

.videoPlaceholder {
	display: flex;
	justify-content: center;
	height: inherit;
	width: inherit;
}

// In mobile views, the image capture button pushes the video placeholder icon to the top of the parent container
// Apply the same style here so that the video placeholder stays in the middle
@media only screen and (max-width: $tablet-width-inflection) and (orientation: portrait) {
	.videoPlaceholderWithImageCapture {
		margin-top: auto;
	}
}

// Lower the size of the placeholder text on smaller devices
@media only screen and (max-width: $screen-width-inflection) {
	.iconWrapper {
		svg {
			height: 50px;
			width: 50px;
		}
	}

	.placeholderText {
		font-size: 24px;
	}
}

// Lower the size of the loading text on the next size
@media only screen and (max-width: $medium-screen-size-text-cutoff) {
	.placeholderText {
		font-size: 20px;
	}
}

// Lower the size of the loading text on smaller devices (extra degree)
@media only screen and (max-width: $small-screen-size-text-cutoff) {
	.placeholderText {
		font-size: 16px;
	}
}

// Also worry about smaller devices in landscape mode
@media only screen and (max-height: $medium-screen-size-text-cutoff) and (orientation: landscape) {
	.placeholderText {
		font-size: 12px;
	}
}
