// Copyright 2020 Epic Systems Corporation

@import "../../styles/_utilities.scss";

$link-color: #00a8e6;

.browserList {
	margin: 10px;

	display: inline-flex;
	flex-flow: column nowrap;

	.browserLink {
		display: inline-flex;
		align-items: center;
		color: $link-color;
		text-decoration: none;
		font-size: 2rem;

		.browserIcon {
			margin: 10px;
		}
	}

	// put the icon in the CSS instead of the HTML, because it's entirely decorative
	a.browserLink::after {
		content: url(launch.svg);
		display: block;
		height: 1.7rem;
		width: 1.7rem;
		margin: 0.1rem;

		// try to match the baseline
		position: relative;
		bottom: 0.1rem;
	}

	@include focusedOrHovered("a.browserLink") {
		text-decoration: underline;
	}
}
