// Copyright 2022 Epic Systems Corporation

@import "../../../styles/_colors.scss";
@import "../../../styles/_dimensions.scss";

.singleQuestion {
	text-align: start;
	margin: 0 5px 0 5px;
	@media screen and (min-width: $screen-width-inflection) {
		margin-bottom: 1.2rem;
	}
	@media screen and (max-width: $screen-width-inflection) {
		margin-bottom: 0.5rem;
	}
}

.groupLegend {
	display: flex;
	align-items: center;
	margin-bottom: 5px;
	flex-direction: column;
	svg {
		fill: $neutral-button-color;
	}
	@media screen and (max-width: $screen-width-inflection) {
		flex-direction: row;
		svg {
			margin-right: 0.3em;
		}
	}
}

.legendDropdownContainer {
	display: flex;
	width: 100%;
	justify-content: center;
	margin-bottom: 10px;

	@media screen and (max-width: $screen-width-inflection) {
		justify-content: space-between;
		margin-bottom: 0px;
	}
}

.toggleDropDown {
	svg {
		transition: transform 0.4s ease;
	}
	background: none;
	border-style: none;
}

.questionList {
	padding: 4px; // Add 4px of padding to support drop shadow in cards
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	flex-wrap: nowrap;
	width: 100%;

	> li {
		width: 100%;
		@media screen and (max-width: $screen-width-inflection) {
			margin-bottom: 0;
		}
		margin-bottom: 0.5rem; // Padding for non-mobile
	}

	> span {
		@media screen and (max-width: $screen-width-inflection) {
			margin-bottom: 8px;
		}
		margin-bottom: 15px; // Padding for non-mobile
	}
}
