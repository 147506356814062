// Copyright 2020-2021 Epic Systems Corporation

@use "../../styles/_colors.scss" as *;
@use "../../styles/_utilities.scss" as *;

.modalContainer {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100; // highest z-index

	background-color: $modal-background;
	backdrop-filter: blur(5px);

	display: flex;
	justify-content: center;
	align-items: center;
}

.modalCard {
	background: #fefefe;
	padding: 20px;
	border-radius: 10px;
	box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.2);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-width: 500px;
	text-align: center;
}

.modalCard-wide {
	max-width: 650px;
}

.title {
	font-size: 1.4rem;
	font-weight: bold;
	text-align: left;
	width: 100%;
}

.bodyText {
	font-size: 1.2rem;
	white-space: pre-wrap;
	&.secondary {
		text-align: left;
		padding-top: 10px;
	}
}

.timerText {
	font-size: 2.5rem;
	margin-top: 15px;
}

.buttons {
	margin-top: 10px;

	button {
		margin: 15px 5px 0px;
		min-width: 140px;
	}
}

.checkbox {
	display: flex;
	align-items: center;
	margin-top: 12px;
	input {
		margin-right: 5px;
		width: 20px;
		height: 20px;
	}
}

.splitButtons {
	display: flex;
	justify-content: space-between;
	width: 100%;

	.buttons {
		margin-top: 5px;

		button {
			min-width: 140px;
			margin: 10px 0px 0px 10px;
		}
	}
}

.screenReaderOnly {
	position: absolute;
	width: 0;
	height: 0;
	clip: rect(0, 0, 0, 0);
}
