// Copyright 2023 Epic Systems Corporation

.lobby {
	display: flex;
	flex-direction: row;
	gap: 10px;
	p {
		margin: 0px;
		font-size: 1.1rem;
	}
}

.deviceTray {
	display: flex;
	flex-direction: row;
	gap: 10px;
	p {
		margin: 0px;
	}
	border-top-color: #444;
	border-top: 1px solid #444;
}
