// Copyright 2021-2024 Epic Systems Corporation

@use "../../styles/_utilities.scss" as *;

.tooltip,
.tooltipPointer {
	display: none;
}

// show a hovered tooltip on top of a focused one
@media (hover: hover) {
	*:hover .tooltip {
		z-index: 49;
	}
}

*:focus .tooltip {
	z-index: 48;
}

@mixin displayTooltip {
	.tooltip {
		background: #444;
		border-color: #444 transparent;
		border-radius: 5px;
		top: 40px;
		color: #fff;
		display: block;
		padding: 5px 10px;
		position: absolute;
		white-space: nowrap;
		font-size: 16px;
	}

	.tooltip.left {
		left: 50% #{"/*!rtl:ignore*/"}; // this style does not change for ltr vs rtl
		transform: translate(-50%) #{"/*!rtl:ignore*/"}; // this style does not change for ltr vs rtl
	}

	.tooltip.right {
		right: 50% #{"/*!rtl:ignore*/"}; // this style does not change for ltr vs rtl
		transform: translate(50%) #{"/*!rtl:ignore*/"}; // this style does not change for ltr vs rtl
	}

	.tooltipPointer {
		border: solid;
		border-color: #444 transparent;
		border-width: 0 10px 10px 10px;
		top: 30px;
		content: "";
		display: block;
		left: calc(50% - 10px);
		position: absolute;
		z-index: 50;
	}
}

// only apply the effects to devices with hover-capable input (mouses)
@include focusedOrHoveredNotMobile("button") {
	@include displayTooltip;
}

//styling for the Language Selector in the pre-call lobby
@include focusedOrHoveredNotMobile("select") {
	& + div {
		@include displayTooltip;
	}
}

// Catch-all to show tooltips belonging to any parent on hover.
// This DOES NOT show tooltips on focus!
// Since this selector matches on any type of focused element, it causes tooltips to be sticky for
// BaseButton elements. This is because of a trick in BaseButton structure:
//
// 	<button>
//    	<div tabindex="-1">
//	   		{content}
//     	</div>
//	</button>
//
// When the user clicks on the button, focus is applied to the div (this is possible since tabindex is -1)
// However, when the user tabs to the button, focus is applied to the button, since the div with tabindex -1
// set isn't in the tab order!
// The two style rules above don't apply to the element focused by mouse clicks. The mouseover event percolates
// up to the button/select element though, and is still able to trigger the tooltip to be shown.
@include hoveredNotMobile("*") {
	& > {
		@include displayTooltip;
	}
}
