// Copyright 2023-2024 Epic Systems Corporation

@use "../../styles/_dimensions.scss" as *;
@import "../../styles/_colors.scss";
@import "../HardwareTest/SettingsPanel.module.scss";

.bodyText {
	padding-bottom: 10px;
}

main {
	position: relative;
}

.expandingContents {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: stretch;

	transition: width 0.4s ease 0s, visibility 0s linear 0.4s;

	@media screen and (max-width: $screen-width-inflection) {
		flex-direction: column;
	}
}

.scrollContainer {
	@media screen and (max-width: $screen-width-inflection) {
		overflow-y: auto;
		width: 100%;
		flex-grow: 1;
	}
}

div.hardwareCard {
	width: fit-content;
	padding: 15px;
	align-items: flex-start;
}

.mobileHardwareBackground {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0 #{"/*!rtl:ignore*/"}; // remove rtl:ignore if left/right values differ
	right: 0 #{"/*!rtl:ignore*/"}; // remove rtl:ignore if left/right values differ
	margin: unset;
	justify-content: flex-start;
	overflow: hidden;
	width: 100%;
	background-color: white;
	padding: 15px;
	display: flex;
	flex-direction: column;
}

.nameAndVideoContainer {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: center;
	align-items: flex-end;
	> * {
		flex-grow: 1;
		flex-basis: 0;
	}
	button {
		min-width: max-content;
	}
	gap: 10px;
	font-size: 1rem;
}

.mainButtonContainer {
	width: 100%;
	margin-top: 15px;
	button {
		width: 100%;
		font-size: 1rem;
	}
	padding-bottom: env(safe-area-inset-bottom);
}

.messageList {
	margin: 0;
	list-style-type: "-";
	padding: 0 0.5em;
	font-size: 1rem;
	color: $WPColor-TextBody;

	li {
		padding-left: 0.5em;
	}
}

h1.hardwareTitle {
	font-size: 1.3em;
	margin: 0 5px 12px 0px;
	text-align: start;
	color: $WPColor-TextBody;
}

.hardwareSubtitle {
	color: $WPColor-TextBody !important;
	font-size: 1rem !important;
	margin: 5px 5px 0px 0px !important;
	text-align: start;
}

.disabledIcon {
	width: 10%;
	height: 10%;
	fill: white;
}

.actionContent {
	flex: 1;
	width: $hardware-test-action-content;
	@media screen and (max-width: $screen-width-inflection) {
		width: 100%;
	}
}

.videoAndToggles,
.mobileToggleRow {
	border: 1px solid $WPColor-CardSectionSeparator;
	border-radius: 4px;
	background-color: #f2f2f2;
	margin-bottom: 15px;
}

.mobileToggleRow {
	margin-top: 15px;
}

.statusMessages {
	text-align: left;
}

.hiddenHardwareTest {
	display: none;
}

.settingsPanelPlaceholder {
	@include settingsPanelSpace;
}
