﻿// Copyright 2023 Epic Systems Corporation

.hidden {
	display: none;
}

.hardwareMenu {
	&:focus-visible {
		border-color: black;
		outline: none;
	}
	border: 2px solid;
	border-color: transparent;
}
