// Copyright 2021-2024 Epic Systems Corporation

@use "../../styles/_dimensions.scss" as *;

//change to make mobile first
.captureContainer {
	z-index: 10;
	display: flex;
	flex-direction: column;
	align-items: center;
}
[dir="ltr"] .captureContainer {
	margin-right: auto;
	margin-left: env(safe-area-inset-left, 5px);
}
[dir="rtl"] .captureContainer {
	margin-left: auto;
	margin-right: env(safe-area-inset-right, 5px);
}

.captureButton {
	position: relative;
	border-radius: 50%;
	height: 55px;
	width: 55px;
	margin: 5px;
	background-color: #505050;

	/* Override default padding on iOS */
	padding: 0px;

	svg {
		width: 44px;
		height: 44px;
	}
}

.hoverCaptureButton {
	background-color: #ffffff;
	border-radius: 50%;
	box-shadow: inset 0 0 0 3px #ffffff, inset 0 0 0 6px #000;
}

.redX {
	position: relative;
	border-radius: 50%;
	height: 30px;
	width: 30px;
	background-color: #ff0000;

	/* Override default padding on iOS */
	padding: 0px;

	svg {
		height: 20px;
		width: 20px;
	}
}

// the span containing the text is 1-4px taller than the font size depending on character contents and size.
$font-span-increase: 4px;
// top & bottom padding (2.5px each) plus top and bottom border (3px each)
$info-bar-padding: 11px;
// 1% of the bottom of the main participant in mobile multiparty view, where main participant is 75% of the total view height
$main-participant-border: 0.75vh;
// how many px the image capture button should be above the name bar
$height-above-bar: 1px;
$total-margin: calc(
	#{$font-span-increase} + #{$info-bar-padding} + #{$main-participant-border} + #{$height-above-bar}
);
// the calculated font size from ParticipantNameBar styling plus the margin
$font-size-scale: calc(max(0.75rem, min(0.5rem + 0.6667vw, 1rem)) + #{$total-margin});

// default font size plus margin
$font-size-fixed: calc(16px + $total-margin);

// uses $tablet-width-inflection so that the button stays on the side of the screen in multi-party calls for most tablets
@media only screen and (max-width: $tablet-width-inflection) and (orientation: portrait) {
	.captureContainer:not(.twoPartyCall) {
		width: 100px;
		flex-direction: row;
		justify-content: center;
		margin-top: auto;
		margin-left: 0;
		margin-right: 0;

		// if the browser doesn't support min/max use the fixed size
		margin-bottom: $font-size-fixed;
		margin-bottom: $font-size-scale;
	}

	.captureButton {
		height: 40px;
		width: 40px;

		svg {
			width: 32px;
			height: 32px;
		}
	}
}
