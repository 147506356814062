// Copyright 2021 Epic Systems Corporation

.header:focus {
	outline: none;
}

.networkContainer {
	display: flex;
	justify-content: center;
	align-items: center;
}

.networkLabel {
	margin-right: 10px;
}
