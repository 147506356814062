// Copyright 2022 Epic Systems Corporation

@import "../../styles/_dimensions.scss";

.backgroundOptionPage {
	width: $background-page-width;

	@media (max-width: $small-screen-width-inflection) {
		width: $background-page-width-small;
	}
}
