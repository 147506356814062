// Copyright 2023 Epic Systems Corporation

.toggleGroup {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.deviceIconContainer {
	position: relative;
}

.deviceToggle {
	display: flex;
	flex-direction: row;
	margin-right: 10px;
	align-items: center;
}

.quickActions {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	font-size: 1rem;
	margin: 6px;
	gap: 6px;
}

.settingsViewButtons {
	display: flex;
	flex-direction: row;
	justify-content: end;
	flex-grow: 1;

	button:not(:last-child) {
		margin-right: 10px;
	}
}
