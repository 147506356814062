// Copyright 2021 Epic Systems Corporation

@import "../../../styles/_utilities.scss";
@import "../../../styles/_colors.scss";

// Scaling is currently based around having minimum size font when screen width is at or below 600px, but
// will begin scaling up to their max at larger widths. Scaling will stop at a maximum width of 1200px.
$font-size-scale: max(0.75rem, min(0.375rem + 1vw, 1.125rem));
$icon-size-scale: max(1rem, min(0.375rem + 1vw, 1.5rem));

// set font size for browsers that that don't support max and min (ios < 11.3, chromium < 79 etc)
// https://developer.mozilla.org/en-US/docs/Web/CSS/max()#browser_compatibility
// modify these according to media window width at the bottom
$font-size: 12px;
$icon-size: 16px;

.toastsContainer {
	position: absolute;
	left: 50%;
	transform: translate(-50%, 0);
	width: 80%;
	margin-top: 5px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.toast {
	display: flex;
	flex: 1;
	justify-content: space-between;
	align-items: center;
	background: $toast-transparent-color;
	color: #ffffff;
	border-radius: 15px;
	margin-top: 5px;
	padding: 5px 15px;
	text-align: center;
	cursor: default;
	outline: none;
	pointer-events: auto;

	font-size: $font-size;
	font-size: $font-size-scale;

	@include focusedOrHovered() {
		background: $toast-solid-color;
	}

	svg {
		border-radius: 100%;
		margin-right: 5px;
		fill: #ffffff;
	}

	&.active {
		border: 3px solid $WPColor-BrandAccentBack;
	}

	&.solid {
		background-color: $toast-solid-color;
	}

	&.success {
		background: $DarkModeColor-PrimaryActionButtonPositiveBack;
		color: white;

		svg {
			background-color: $DarkModeColor-PrimaryActionButtonPositiveBack;
		}
	}
}

.buttonRow {
	button {
		margin: 2.5px 0px 2.5px 5px;
	}
}

.dismissButton {
	height: $icon-size;
	width: $icon-size;
	height: $icon-size-scale;
	width: $icon-size-scale;
	border-radius: 50%;
	border: none;
	background: none;
	margin-left: 2.5px;
	margin-right: -10px;
	margin-top: 1.5px;
	background: none;

	display: flex;
	align-items: center;
	justify-content: center;

	@include focusedOrHovered() {
		background: $toast-dismiss-button-color;
	}

	svg {
		height: $icon-size;
		width: $icon-size;
		width: $icon-size-scale;
		height: $icon-size-scale;
		fill: white;
		margin: 0;
	}
}

// Allow toast buttons to wrap on smaller screens
@media only screen and (max-width: 800px) {
	.toast.solid {
		flex-direction: column;
	}
}

// resizing for non-max/min larger browsers
// use a piece-meal / step-wise approach to approximate our scaling over a few different screen-sizes
@supports not (font-size: $font-size-scale) {
	// enforce minimums below below 800px
	@media only screen and (max-width: 800px) {
		.toast {
			font-size: 12px;
		}
	}

	// use scaled value of 800px from (800px to 1000px)
	@media only screen and (max-width: 1000px) {
		.toast {
			font-size: 14px;
		}
	}

	// use scaled value of 1000px from (1000px to 1200px)
	@media only screen and (max-width: 1200px) {
		.toast {
			font-size: 16px;
		}
	}

	// allow maximums above 1200px
	@media only screen and (min-width: 1200px) {
		.toast {
			font-size: 18px;
		}
	}
}
