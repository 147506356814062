// Copyright 2021 Epic Systems Corporation

@use "../../styles/_colors.scss" as *;
@use "../../styles/_utilities.scss" as *;

$min-height: 120px;

.modalContainer {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 85; // this < header (HeaderBar.module.scss)

	background-color: $modal-background;
	backdrop-filter: blur(5px);

	display: flex;
	justify-content: center;
	align-items: center;

	.modalCard {
		background: #fefefe;
		padding: 20px;
		border-radius: 10px;
		box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.2);
		display: flex;
		flex-direction: column;
		align-items: center;
		max-width: 400px;
		text-align: center;
		min-height: $min-height;

		display: flex;
		justify-content: space-around;

		&.shiftDown {
			// Position the card 1 card-height below if it was centered at 50%, which means the bottom is 1.5 heights below 50%
			//   If the card ends up larger than expected, it'll expand towards the center of the page
			// We want to use css max here, rather than SASS max, because SASS can't compare vh and px at compile-time
			//   but by default, using SASS variables will use the SASS function (both are named the same thing)
			//   So, we have to explicitly interpolate them into the css
			bottom: max(10px, (50% - #{$min-height * calc(3 / 2)}));
			position: absolute;
		}
		&.shiftUp {
			// Same thing here, but from the top instead of bottom
			top: max(10px, (50% - #{$min-height * calc(3 / 2)}));
			position: absolute;
		}
		&.bottomAlign {
			// Put the card top at 25% up the page
			bottom: max(10px, (25% - #{$min-height}));
			position: absolute;
		}

		span {
			font-size: 1.2rem;
			white-space: pre-wrap;
		}

		button {
			margin-top: 10px;
			border: none;
			border-radius: 4px;
			box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.2);
			color: white;
			cursor: pointer;
			line-height: 1.5rem;
			padding: 0.25rem 0.75rem;

			background-color: #0091ea;

			@include focusedOrHovered() {
				background-color: #007bc7;
			}
		}
	}
}
