// Copyright 2023 Epic Systems Corporation

.videoPreview {
	display: flex;
	font-size: 0;
	position: relative;

	.audioPreview {
		position: absolute;
		bottom: 6px;
		left: 6px;
	}

	.overlayButtons {
		position: absolute;
		bottom: 6px;
		right: 6px;
		display: flex;
		flex-direction: row;
		align-items: center;

		button {
			margin: 0px;
			&:not(:last-child) {
				margin-right: 6px;
			}
		}
	}
}

.disabledPlaceholder {
	height: 0;
	width: 100%;
	position: relative;
	background-color: black;

	.disabledIcon {
		$disabledIconWidth: 20%;

		position: absolute;
		width: $disabledIconWidth;
		left: calc(
			50% - $disabledIconWidth / 2
		); // move left 50%, then subtract 0.5*20% to account for the width

		// top is percentage of height, margin-top is percentage of width. Since the icon is a square, we move the icon up by half the width (half the height) to center it
		top: 50%;
		margin-top: calc(-1 * $disabledIconWidth / 2);
		fill: white;
	}

	border-radius: 4px;
}

.videoFeed {
	width: 100%;
	height: 100%;
	border-radius: 4px;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
}
