// Copyright 2021 Epic Systems Corporation

@import "../../../styles/_utilities.scss";
@import "../../../styles/_colors.scss";
@import "../../../styles/_dimensions.scss";
@import "../../../styles/theme/colorUtils.scss";

.changePageButton {
	height: $page-button-size;
	width: $page-button-size;
	padding: 2px;
	border: none;
	background: none;

	svg {
		height: $page-button-size;
		width: $page-button-size;
	}

	svg path {
		stroke: $carousel-blue;
	}

	&:disabled svg path {
		stroke: grey;
	}

	&:not(:disabled) {
		cursor: pointer;
	}

	@include focusedOrHovered() {
		&:not(:disabled) svg path {
			stroke: WPDarken($carousel-blue, 20%);
		}
	}

	@include focusedOrHovered() {
		&.lighten svg path {
			stroke: WPLighten($carousel-blue, 20%);
		}
	}
}
