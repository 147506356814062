// Copyright 2020-2024 Epic Systems Corporation

@use "../../styles/_dimensions.scss" as *;

.cardContainer {
	padding: 0px 10% 60px 10%;
	position: relative;
	overflow: auto;

	& > div {
		margin-left: auto;
		margin-right: auto;
		min-width: 0;
		max-width: $card-width-large;
	}

	&.wideCard > div {
		max-width: $card-width-very-large;
	}
}

// Card positioning for small screens

// If the medium-size preview could overlap with the card, and eventually make it narrower
$overlapWidth: $card-width-large + (2 * $float-video-width-max-3);
$overlapHeight: $card-height-est + $header-height + $float-video-height-3 + 10px;
@media only screen and (max-width: $overlapWidth) and (max-height: $overlapHeight) {
	.cardContainer {
		padding-left: 0;
		padding-right: 0;

		// Don't shift card if it's know to be short and can't overlap header
		&.shortCard > div {
			@media (min-height: ($card-height-short-est + $header-height + $float-video-height-3 + 10px)) {
				margin-left: auto;
			}
		}

		// Bottom padding ensures you can scroll the card above the preview (with 10px margins above/below preview)
		padding-bottom: $float-video-height-4 + 20px;

		& > div {
			width: $card-width-small;
		}

		// If the page is short and narrow enough to force overlap, but the horizontal overlap is limited (<50% of the video)
		//   shift the card fully to the left to show as much as possible
		$partialOverlapWidth: calc($card-width-small + ($float-video-width-max-4 / 2));
		$partialOverlapHeight: $card-height-est + $header-height + $float-video-height-4;
		@media (min-width: $partialOverlapWidth) and (max-height: $partialOverlapHeight) {
			& > div {
				margin-left: 0;
			}
		}

		// If there's still enough horizontal space for the card and the preview to live side by side,
		//   shift the card to the left, centered in the space left of the preview
		// min-width: 360 (card) + 213 (16:9 width for the smallest possible preview height) + 10 (margin)
		@media (min-width: ($card-width-small + $float-video-width-max-4)) {
			// There's no overlap when we shift left, so we don't need padding to allow scrolling
			// We do still want some padding, so we don't cut off the pulse animation of the join button
			padding-bottom: 50px;

			& > div {
				// Leave the card full size when we can
				width: $card-width-large;
				// Left margin should be two thirds of what's left after the card width, preview width, and margin on the preview right side
				// Since the calc assumes a 16:9 video, but this resolution more commonly has a 4:3 resolution, this looks more centered for the common case
				//   and still looks good with 16:9
				margin-left: calc((100vw - #{($card-width-large + $float-video-width-max-3)}) / 1.5);

				// No longer enough room to fit the wide version of the card, switch to narrow
				@media (max-width: ($card-width-large + $float-video-width-max-3)) {
					// Make the card narrow
					width: $card-width-small;
					// Adjust margin for narrower card size
					margin-left: calc((100vw - #{($card-width-small + $float-video-width-max-3)}) / 2);

					// And adjust the margin if we're using the smallest preview size
					@media (max-width: ($card-width-small + $float-video-width-max-3)) {
						margin-left: calc((100vw - #{($card-width-small + $float-video-width-max-4)}) / 2);
					}
				}
			}
		}
	}
}
