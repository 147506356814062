// Copyright 2021 Epic Systems Corporation

.applyRow {
	display: flex;
	flex-flow: column;
}

.applyButton {
	display: flex;
	justify-content: center;
	padding: 5px 0px;
}

.visibilityInfoWrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0px 10px;

	label {
		margin: 0 5px;
	}
}
