// Copyright 2020 Epic Systems Corporation

@import "../../styles/_colors.scss";
@import "../../styles/_dimensions.scss";

.backgroundSelector {
	display: flex;
	flex-direction: column;
	background: $EVCColor-MenuBack;
	color: white;
	border-top: none;
	font-size: 16px;
	padding: 0 0 10px 0;

	@media (max-width: $screen-width-inflection), (max-height: $minimum-screen-size-for-header) {
		svg {
			height: 25px;
			width: 25px;
		}
	}
}
