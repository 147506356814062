// Copyright 2022 Epic Systems Corporation

@import "../../styles/_dimensions";
@import "../../styles/_colors.scss";

.surveyForm {
	padding: 10px;
	width: 100%;
}

.buttonGroup {
	display: flex;
	justify-content: center;

	button:not(:last-child) {
		margin-right: 10px;
	}
}
