.noFocusDiv {
	position: absolute;
	width: 1px;
	height: 1px;
}

.childWrapper {
	width: 100%;
	height: 100;
	display: inherit;
	justify-content: inherit;
	align-items: inherit;
}
