// Copyright 2022 Epic Systems Corporation

@use "../../../styles/_colors.scss" as *;
@import "./_menuUtils.scss";

$single-page-menu-width: 400px;

.wrapper {
	@include menu-wrapper-base($single-page-menu-width);
}

.menuPage {
	@include menu-page-base();
}

@media (max-width: $single-page-menu-width) {
	.wrapper {
		border-bottom-left-radius: 0;
	}
}
