// Copyright 2021 Epic Systems Corporation

@use "../../../styles/_colors.scss" as *;
@use "../../../styles/_dimensions.scss" as *;
@use "../../../styles/_utilities.scss" as *;
@import "./_menuUtils.scss";

.dropDown {
	@include menu-wrapper-base();

	transition: max-height #{$page-transition-duration} ease-in,
		min-height #{$page-transition-duration} ease-in;
	background: $EVCColor-MenuBack;
	z-index: 6;
	pointer-events: auto;
	visibility: visible;
}

.wrapper {
	display: flex;
	justify-content: flex-end;
}

// the button tray can be shown due to width and in place of the standard header due to screen height
@media (max-width: $screen-width-inflection), (max-height: $minimum-screen-size-for-header) {
	.dropDown {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: $bottom-corner-border-radius;

		// anchor the menu to the left when the button tray is present
		right: unset;
		left: 0;
	}

	.wrapper {
		justify-content: flex-start;
	}
}

@media (max-width: $menu-width) {
	.dropDown {
		border-bottom-right-radius: 0;
	}
}

.menuPage {
	@include menu-page-base();

	&.nextUp {
		position: absolute;
		visibility: hidden;
		left: 100%;
	}

	&.hidden {
		display: none;
	}
}

.title {
	color: #fff;
	font-size: 24px;
	margin-left: 7.5px;
}

.backButton {
	background: none;
	outline: none;
	display: flex;
	align-items: center;
	padding: 1px 10px 1px 5px;
	border: 1px solid $EVCColor-MenuBack;
	border-radius: 5px;
	cursor: pointer;
	width: 100%;

	div {
		display: flex;
		align-items: center;
		outline: none;
		min-height: 30px;
	}

	@include focusedOrHovered() {
		background: $EVCColor-MenuButtonHoverBack;
		border-color: $EVCColor-MenuButtonHoverBorder;
	}
}
