// Copyright 2021-2024 Epic Systems Corporation

@use "../../styles/_colors.scss" as *;
@use "../../styles/_dimensions.scss" as *;
@use "../../styles/_utilities.scss" as *;

$background-color: rgb(36, 37, 37);

.saveContainer {
	button {
		width: 100%;
		margin-top: 10px;
	}
}

.imageCaptureContainer {
	width: 100%;
	height: 100%;
	position: fixed;
	background: rgba(0, 0, 0, 0.7);
	z-index: 95; // header (HeaderBar.module.scss) < this < modal alert (Alerts.module.scss)
	top: 0;
}

.imageWrapper {
	outline: none;
	font-size: 0; // If font size is set, then the wrapper takes up slightly more space on some browsers
	overflow: hidden;
	margin-bottom: 0.5rem;
}

.imageCapturePane {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background-color: $background-color;
	position: relative;
	padding: 15px;

	[dir="ltr"] & {
		padding-left: max(15px, env(safe-area-inset-left, 15px));
	}
	[dir="rtl"] & {
		padding-right: max(15px, env(safe-area-inset-right, 15px));
	}

	img {
		padding: 4px;
		object-fit: contain;
		object-position: left#{"/*!rtl:right*/"};
		width: 100%;
		max-height: 100%;

		// Necessary to use drop shadow as border since `object-fit: contain` will shrink the image within the <img> element
		filter: drop-shadow(0 -2px 0 white) drop-shadow(0 2px 0 white) drop-shadow(-2px 0 0 white)
			drop-shadow(2px 0 0 white);
	}
}

.docType {
	color: white;
}

.exitButton {
	height: 25px;
	width: 25px;
	right: 10px;
	top: 10px;
	position: absolute;
	border-radius: 50%;
	background-color: #000000;
	border: 1px solid #000000;

	/* Ensure button is not squished due to default padding on iOS */
	padding: 0px;

	svg {
		width: 15px;
		height: 15px;
	}

	@include focusedOrHovered() {
		background-color: #111;
		border-color: #333;
		outline: none;
	}
}

.retakeDiscardGroup {
	flex-shrink: 0; // The image (sibling element) should shrink rather than the buttons
	display: flex;
	flex-wrap: wrap;
	height: auto;
	min-height: 32px;

	button {
		margin-bottom: 5px;
	}

	button:not(:last-child) {
		margin-right: 5px;
	}
}

.header {
	font-size: 16px;
	color: white;
	padding-right: 40px;
}

.scaleImage {
	align-items: flex-start;
}

.verticalStack {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.inputGroup {
	select {
		padding: 5px;
		text-overflow: ellipsis;
	}

	input {
		width: 100%;
		height: 33px;
		margin-top: 5px;
	}
}

/* Take up all available space in the image panel, to be the foundation of scaling the image to display inside */
.imageSection {
	width: 100%;
	height: 100%;
	flex-shrink: 1;
	// This prevents the image from overflowing, without hiding overflow. Specifically, this allows tooltips to work.
	// If instead of `min-height: 0` we set `overflow: hidden`, the tooltips would be cut off. By default, `min-width`
	// is `auto` which doesn't let this flex-box constrain it's children's size.
	min-height: 0;
	display: flex;
	flex-direction: column;
	margin-bottom: 0.5rem;
}

@media only screen and (min-width: $small-screen-width-inflection) {
	.inputGroup {
		display: flex;
		align-items: flex-start;
		flex-direction: column;

		select + input {
			margin-left: 5px;
		}

		.verticalStack {
			width: auto;
			max-width: 30%;
			padding-right: 1px;
		}
	}
}

@media only screen and (min-width: $small-screen-width-inflection) and (min-height: $small-screen-width-inflection) {
	.header {
		font-size: 24px;
	}

	.exitButton {
		right: 15px;
		top: 15px;
	}
}

@media only screen and (min-width: $small-screen-width-inflection) {
	.imageCapturePane {
		max-width: 75%;
	}
}

@media only screen and (min-width: $screen-width-inflection) {
	.imageCapturePane {
		padding: 30px;
	}
}
