// Copyright 2023-2024 Epic Systems Corporation

@use "../../styles/_utilities.scss" as *;
@use "../../styles/_colors.scss" as *;

.baseButton {
	background-color: $EVCColor-MenuBack;
	border: 1px solid $EVCColor-MenuBack;
	color: $EVCColor-HeaderButtonFore;
	border-radius: 5px;
	cursor: pointer;
	padding: 0;
	display: flex;
	justify-content: space-between;
	width: 100%;
	outline: 0;

	@include focusedOrHovered() {
		background-color: $EVCColor-MenuButtonHoverBack;
	}
}

.labelText {
	font-size: 18px;
	margin-left: 5px;
	margin-top: auto;
	margin-bottom: auto;
	cursor: inherit;
	text-align: left;
}

.switchAndLabelWrapper {
	display: flex;
	vertical-align: middle;
	justify-content: left#{"/*!rtl:right*/"};
	width: 100%;
}
