@import "../../styles/_colors.scss";
@import "../../styles/_utilities.scss";
@import "../../styles/_dimensions.scss";
@import "../../styles/theme/branding.scss";

.ChatDisclaimer {
	color: $WPColor-ActionButtonFore;
	position: relative;
	font-size: 1rem;
	background-color: $DarkModeColor-ChatBackground;
	z-index: 1;
	padding-left: 0.7rem;
	padding-right: 0.7rem;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}
