@import "../../styles/_colors.scss";
@import "../../styles/_utilities.scss";
@import "../../styles/_dimensions.scss";
@import "../../styles/theme/branding.scss";

.ChatWarning {
	color: $WPColor-ActionButtonFore;
	position: relative;
	font-size: 1rem;
	background-color: $DarkModeColor-PrimaryActionButtonNegativeBack;
	z-index: 1;
	padding-left: 0.7rem;
}
