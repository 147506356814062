// Copyright 2020 Epic Systems Corporation

@import "../../../styles/_colors.scss";

@mixin volume-indicator-container($background-color, $border-color) {
	height: 24px;
	width: 14px;
	overflow: hidden;
	border: $border-color solid 1px;
	border-radius: 4px;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	position: relative;

	.transparentBackground {
		filter: opacity(25%);
		background-color: $background-color;
		position: absolute; // Position as the background behind the full audio indicator
		width: 100%;
		height: 100%;
	}
}

.volumeIndicatorContainer {
	@include volume-indicator-container($WPColor-BrandAccentBack, $WPColor-BrandAccentBack);

	&.darkTheme {
		@include volume-indicator-container($WPColor-BrandAccentBack, $WPColor-BrandAccentBack);
	}
}

.disabledVolumeIndicatorContainer {
	@include volume-indicator-container(rgb(100, 100, 100), rgba(100, 100, 100, 0.6));
}

.volumeIndicator {
	width: 110%; // 100% makes this 1px too narrow on some devices
	transition: 0.1s;
	background-color: $WPColor-BrandAccentBack;

	.darkTheme & {
		background-color: $WPColor-BrandAccentBack;
		background-image: linear-gradient(to top, $WPColor-BrandAccentBack, $WPColor-BrandAccentBack);
	}
}
