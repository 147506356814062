// Copyright 2021 Epic Systems Corporation

.bodyText {
	text-align: left;
	padding-left: 10px;
}

.steps {
	text-align: left;
}

ol > li {
	padding-bottom: 5px;
}
