// Copyright 2021 Epic Systems Corporation

@use "../../styles/_utilities.scss" as *;
@use "../../styles/_colors.scss" as *;
@use "../../styles/_dimensions.scss" as *;

.baseButton {
	background-color: $EVCColor-HeaderButtonBack;
	border: 1px solid $EVCColor-HeaderButtonBack;
	color: $EVCColor-HeaderButtonFore;
	border-radius: 5px;
	cursor: pointer;
	position: relative;
	padding: 0; // override user agent style sheet

	svg {
		fill: $EVCColor-HeaderButtonFore;
		transition: 0.4s ease;
	}

	.disabled {
		opacity: 0.6;
		pointer-events: none;
	}

	.labelText {
		font-size: 18px;
		margin: 0 5px;
	}

	@media (max-width: $screen-width-inflection), (max-height: $minimum-screen-size-for-header) {
		.labelText {
			font-size: 14px;
		}
	}
}

.toolTipButton {
	// Style for tooltip and other misc content
	& > div {
		position: relative;
		display: flex;
		padding: 1px 2px;
		align-items: center;
		justify-content: center;
		outline: none;
		height: 100%;
		width: 100%;
	}
}

.iconAndLabelWrapper {
	display: flex;
	align-items: center;
}
