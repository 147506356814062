// Copyright 2021-2024 Epic Systems Corporation

@use "../../../styles/_colors.scss" as *;
@use "../../../styles/_dimensions.scss" as *;
@use "../../../styles/_utilities.scss" as *;

$switch-width-normal: 45;
$switch-width-small: 29;
$icon-height-normal: 24;
$icon-height-small: 16;

.moderatorMenu {
	z-index: 10;
	display: flex;
	overflow: hidden;
	flex-direction: column;
	float: right;
	width: 100%;

	// This transform is necessary so that the info bar does not disappear on rotation
	transform: translateZ(0);
	-webkit-transform: translateZ(0);

	&.reversed {
		flex-direction: column-reverse;
		> .buttonGrouping {
			flex-direction: column-reverse;
		}
	}
}

.moderatorRow {
	padding: 5px 5px;
	text-overflow: ellipsis;
	color: grey;
	background: none;
	border: none;
	border-radius: 0;
	outline: none;
	max-width: 100%;
	position: relative;

	&:not(:disabled, [aria-disabled="true"]) {
		cursor: pointer;

		.buttonIcon > * {
			fill: white;
		}

		@include focusedOrHovered() {
			background: $EVCColor-MenuButtonHoverBack;
		}

		color: white;
	}

	.buttonIcon > * {
		fill: grey;
	}

	.buttonIcon,
	.bell {
		height: #{$icon-height-normal}px;
		width: #{$switch-width-normal}px;
		margin-right: 0px;
	}

	&.strip {
		@media screen and (max-width: $screen-width-inflection),
			screen and (max-height: $shrink-participant-menu-height) {
			.buttonIcon {
				height: #{$icon-height-small}px;
				width: #{$switch-width-small}px;
				min-width: #{$switch-width-small}px;
			}

			.switch {
				height: #{$icon-height-small}px;
				width: #{$switch-width-small}px;
				min-width: #{$switch-width-small}px;
			}
		}
	}

	.switch {
		width: #{$switch-width-normal}px;
		height: #{$icon-height-normal}px;
	}

	&.menu {
		background: $EVCColor-MenuBack;
		border: 1px solid $EVCColor-MenuBack;
		border-radius: 5px;

		&:not(:disabled, [aria-disabled="true"]) {
			@include focusedOrHovered() {
				background: $EVCColor-MenuButtonHoverBack;
				border-color: $EVCColor-MenuButtonHoverBorder;
			}
		}

		.textIconWrapper {
			display: flex;
			align-items: center;
			font-size: 18px;
		}

		@media screen and (max-width: $screen-width-inflection),
			screen and (max-height: $shrink-participant-menu-height) {
			.textIconWrapper {
				font-size: 14px;
			}
		}
	}
}

.requestText {
	color: grey;
}

.labelText {
	margin-left: 5px;
	font-size: 18px;
	text-align: left;

	&.stripText {
		font-size: 14px;
	}
}

.bell {
	g > rect,
	g > path {
		fill: $EVCColor-NotificationBack;
	}
}

@media screen and (max-width: $screen-width-inflection),
	screen and (max-height: $minimum-screen-size-for-header) {
	.stripText {
		font-size: 12px;
	}
}

@media screen and (max-width: $small-screen-width-inflection) {
	.stripText {
		font-size: 10px;
	}
}

.buttonGrouping {
	display: flex;
	flex-direction: column;
	vertical-align: middle;

	// For all except the last child, add a divider on the bottom of the group
	&:nth-last-child(n + 2) {
		border-bottom: 1px solid #888;
	}
}

// When reversed, the divider should be above the group
.reversed > .buttonGrouping:nth-last-child(n + 2) {
	border-top: 1px solid #888;
	border-bottom: 0px;
}
