// Copyright 2023 Epic Systems Corporation

@import "../../styles/_colors.scss";
@import "../../styles/theme/colorUtils";

.invalidInput {
	background-color: $WPColor-NegativeFaint;
}

.invalidCharactersMessage {
	color: WPContrastCompliantColor(
		$WPColor-NegativeBright,
		rgba(0, 0, 0, 0.7),
		$WCAG-AALargeTextContrastRatio,
		false
	);

	text-align: left;
	font-size: 0.9rem;
	line-height: 1em;
	vertical-align: middle;
	min-height: 1.5rem;
	margin-top: 0.25rem;
	margin-bottom: 0.3rem;
	max-width: 100%;
	font-weight: bold;

	&.yield {
		height: 1rem;
		width: 1rem;
	}
}
