// Copyright 2023 Epic Systems Corporation

@use "~/styles/_colors.scss" as *;

.loadingEllipsis {
	display: flex;
	align-items: flex-end;
	margin-left: 0.25rem;
	margin-top: 0.6rem;
}

@keyframes bouncingCircle {
	from {
		background-color: $toast-transparent-color;
		bottom: 0.125rem;
	}
	to {
		background-color: $WPColor-TextOnColoredBack;
		bottom: 0.35rem;
	}
}

.loadingEllipsis span {
	height: 0.5rem;
	width: 0.5rem;
	background-color: $toast-transparent-color;
	border-radius: 50%;
	position: relative;

	animation: bouncingCircle;
	animation-duration: 0.54s;
	animation-iteration-count: infinite;
	animation-direction: alternate;
	margin-right: 0.1rem;
	margin-bottom: 0.1rem;
}

.loadingEllipsis span::after {
	content: "\200b";
}

.loadingEllipsis span:nth-of-type(2) {
	animation-delay: 0.18s;
}
.loadingEllipsis span:nth-of-type(3) {
	animation-delay: 0.36s;
}

.TransparentOverlay {
	align-items: center;
	background-repeat: no-repeat;
	display: flex;
	flex-direction: column;
	height: 0%;
	justify-content: center;
	position: absolute;
	text-align: center;
	width: 0%;
	z-index: 100;

	background-color: #494b4e;
	opacity: 90%;
	color: white;
}

.TransparentOverlay.show {
	height: 100%;
	width: 100%;
}
