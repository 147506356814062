// Copyright 2024 Epic Systems Corporation

button.copyright {
	// Make button look like a link
	background-color: transparent;
	border: none;
	cursor: pointer;
	display: inline;

	// Button positioning
	max-width: fit-content;
	margin: auto 0 0.3rem;

	// Copyright text formatting
	text-shadow: 0 0 8px white, 0 0 4px white;
	color: #565656;
	font-size: 0.8rem;
}

.aboutEvcContainer {
	padding: 10px;
	color: white;

	& p {
		margin: 0;
	}

	.aboutEvcSubtitle {
		margin-bottom: 10px;
		font-weight: bold;
		font-size: 14pt;
	}
}
