// Copyright 2020-2021 Epic Systems Corporation

@use "../../styles/_colors.scss" as *;
@use "../../styles/_dimensions.scss" as *;

// How to style the main feed when the participant strip is vertical
.mainParticipant {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
	background: rgba(0, 0, 0, 0.9);
	width: 100%;
	font-size: 24px;
	border: 5px solid transparent;

	video {
		// Give the video element some basic spacing (59px for the header)
		height: 100%;
		width: 100%;
		position: absolute;
		bottom: 0;
		left: 0;
		margin: auto;
		object-fit: contain;
	}
}

.highlight {
	border: 5px solid $WPColor-BrandAccentBack;
}

.screenSharePreview {
	border: 2px solid $EVCColor-ScreenSharePreviewBorder;
}
