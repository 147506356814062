// Copyright 2021 Epic Systems Corporation

@use "../../../styles/_dimensions.scss" as *;
@use "../../../styles/_colors.scss" as *;

.text {
	color: $placeholder-video-text;
	margin: 0px 5px;
	font-size: 18px;
}

.footer {
	height: $paging-section-height;
	display: flex;
	width: 100%;
	justify-content: center;
	vertical-align: middle;
	align-items: center;
	position: absolute;
	bottom: 0;
	touch-action: manipulation;
}
