// Copyright 2021 Epic Systems Corporation

@import "../../styles/_colors.scss";
@import "../../styles/_utilities.scss";
@import "../../styles/theme/colorUtils";

.languageRow {
	width: 100%;
	padding: 5px 10px;
	min-height: 40px;
	font-size: 18px;
	color: $WPColor-TextOnColoredBack;
	background: $EVCColor-MenuBack;
	border: 1px solid $EVCColor-MenuBack;
	outline: none;
	cursor: pointer;

	&:not(:first-child) {
		margin-top: 2px;
	}

	@include focusedOrHovered() {
		background: $EVCColor-MenuButtonHoverBack;
		border-color: $EVCColor-MenuButtonHoverBorder;
	}

	&.disabled {
		color: WPDarken($WPColor-TextOnColoredBack);
	}
}

.iconAndLabelWrapper {
	display: flex;
	align-items: center;

	div,
	svg {
		margin-right: 10px;
	}
}

.icon {
	border-radius: 100%;
	border: 2px solid $WPColor-TextOnColoredBack;
	height: 18px;
	width: 18px;
	background-color: $EVCColor-MenuBack;
	pointer-events: none;

	&.filled {
		background-color: $WPColor-BrandAccentBack;
	}

	&.disabled {
		border: 2px solid WPDarken($WPColor-TextOnColoredBack);
	}
}
