// Copyright 2021-2024 Epic Systems Corporation

@import "../../styles/_colors.scss";
@import "../../styles/_utilities.scss";
@import "../../styles/theme/colorUtils.scss";

.galleryOption {
	border-radius: 10px;
	padding: 1.5px; // fractionally larger padding looks better with white border on dark background
	background-color: black;
	&.isInDarkMode {
		background-color: white;
	}
	width: 100%; // If we don't fix width to parent container, it overflows
	height: 100%;

	@include focusedOrHovered() {
		background-color: WPDarken($toggle-gray, 20%);
	}

	&.selected.selected {
		// Extra specificity for this selector to take priority over dark mode border color
		background-color: $WPColor-BrandAccentBack;
		padding: 5px;
	}
}

.optionWrapper {
	display: inline-flex;
	vertical-align: bottom; // This is baseline by default, which causes the unselected options to be a little lower than the selected
	position: relative;
	padding: 3.5px; // Sums with unselected padding to be 5 px
	width: 120px;
	height: 80px;

	margin: 0 5px;

	&.selected {
		padding: 0px; // Padding is moved from option wrapper to gallery option when selected. This gives slim "borders" around non-selected, and thick around selected

		.bannerText {
			// when selected, we really want the text overlay to reach all the way to the border, even if it extends onto the border by a pixel or two
			border-bottom-left-radius: 5px;
			border-bottom-right-radius: 5px;
		}
	}

	.bannerText {
		position: absolute;
		bottom: 5px;
		left: 5px;
		right: 5px;
		color: white;
		background-color: rgba($color: #000000, $alpha: 0.6);
		justify-content: center;
		// Set to 8px to as not to exceed the small border for unselected options (most obvious in dark mode)
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
		pointer-events: none;
	}
}
