// Copyright 2020 Epic Systems Corporation

@import "../../styles/_colors.scss";

.hardwareSetupRows {
	width: 100%;
	background: #f8f8f8;
	padding: 0 5px;
	border-top: 1px solid #d0d0d0;
	font-size: 16px;

	&.dark {
		background: $EVCColor-MenuBack;
		color: #ffffff;
		border-top: none;
	}
}
