// Copyright 2021-2024 Epic Systems Corporation

@import "../../../styles/_colors.scss";
@import "../../../styles/_dimensions.scss";
@import "../../../styles/_utilities.scss";
@import "../_pinning.scss";

.rowHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-width: 100%;
	padding: 5px;
	font-size: 20px;
	font-weight: bold;
	color: white;

	h2 {
		font-size: 20px;
		margin: 0px;
	}
}

.listWrapper:not(:first-of-type) {
	border-top: solid 1px grey;
}

.nameWrapper {
	display: flex;
	align-items: center;
	max-width: calc(100% - 75px); // give some space for the muted/disabled camera icon
}

@media only screen and (max-width: $screen-width-inflection) {
	.rowHeader {
		font-size: 18px;
	}
}

.participantRow {
	width: 100%;
	padding: 5px 5px 5px 10px;
	min-height: 40px;
	font-size: 18px;
	color: #fff;
	display: flex;
	align-items: center;
	background: $EVCColor-MenuBack;
	border: 1px solid $EVCColor-MenuBack;
	outline: none;
	margin-top: 2px;
	border-radius: 5px;
	max-width: 100%;
	cursor: pointer;

	& > div {
		display: flex;
		flex: 1;
		justify-content: space-between;
		align-items: center;
		max-width: 100%;
	}

	span {
		overflow: hidden;
		text-overflow: ellipsis;
		outline: none;
		white-space: nowrap;
	}

	.iconsWrapper {
		display: flex;
		align-items: center;

		svg {
			height: 24px;
			width: 24px;
		}
	}

	svg:not(:first-child) {
		margin-left: 5px;
	}

	svg > * {
		fill: #fff;
	}

	.bell {
		g > rect,
		g > path {
			fill: $EVCColor-NotificationBack;
		}
	}

	.pin {
		@include pinningIcon(25px, 25px, "pinned", "mobile");

		margin-left: 2px;
	}

	@include focusedOrHovered() {
		background: $EVCColor-MenuButtonHoverBack;
		border-color: $EVCColor-MenuButtonHoverBorder;

		.pin {
			@include activePinIcon(25px, 25px);
		}
	}

	button {
		margin: 0px 2.5px;
	}
}

.removeButton {
	color: $secondary-button-red;
	border: 1px solid $secondary-button-red;
	background-color: $EVCColor-MenuBack;

	@include focusedOrHovered() {
		background-color: black;
		border-color: WPLighten($secondary-button-red, 10%);
	}
}

.actionButtonRow {
	font-size: 14px;
	margin: 1px 1px 1px 6px; // Add extra margin on left to match gap between buttons
	gap: 5px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-end;
}

@media only screen and (max-width: $screen-width-inflection) {
	.actionButtonRow {
		font-size: 12px;
	}
	.participantRow {
		font-size: 14px;
	}
}
