// Copyright 2020-2024 Epic Systems Corporation

@use "../styles/_colors.scss" as *;

@include addBrandingDefaultVariables();

:global {
	* {
		box-sizing: border-box;
	}

	// Use this over height: 100% to work around an iOS 15 issue that causes the page to have a scroll value when it shouldn't be able to scroll
	html {
		position: fixed;
		top: 0;
		bottom: 0;
		// Use rtl:ignore to avoid splitting left/right styles into separate dir="ltr" and dir="rtl" blocks,
		// otherwise it causes problems with getting 100% width for the body.
		// As long as both values are same, no need to generate separate dir specific blocks.
		left: 0 #{"/*!rtl:ignore*/"};
		right: 0 #{"/*!rtl:ignore*/"};
	}

	#root {
		height: 100%;
	}

	body {
		height: 100%;
		overflow: hidden !important;
		margin: 0;
		font-family: -apple-system, BlinkMacSystemFont, "Source Sans Pro", Calibri, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		-webkit-text-size-adjust: 100%;
		-ms-text-size-adjust: 100%;

		background-color: $WPColor-BrandBackground;
	}

	main {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		overflow: hidden;
	}

	button,
	input,
	optgroup,
	select,
	textarea {
		margin: 0;
		font-family: inherit;
		font-size: inherit;
		line-height: inherit;

		&.newMessageButton {
			margin: 0 auto;
		}
	}

	button {
		user-select: none;
		-webkit-tap-highlight-color: transparent;
	}

	iframe {
		border: none;
	}
}
