// Copyright 2022 Epic Systems Corporation

// duration of transitioning from one page to another
// ensure this matches pageTransitionDurationMs in DropDownMenus.tsx
$page-transition-duration: 0.2s;

// ensure this matches dropDownMenuPadding / 2 in DropDownMenus.tsx
$drop-down-padding: 5px;

// border radius of the bottom corners of the menu
$bottom-corner-border-radius: 10px;

// default width of the dropdown menu
$menu-width: 500px;

@mixin menu-wrapper-base($width: $menu-width) {
	position: absolute;
	width: $width;
	max-width: 100%;
	border-bottom-left-radius: $bottom-corner-border-radius;
	overflow: hidden;
	right: 0;
	background-color: $EVCColor-MenuBack;
}

@mixin menu-page-base() {
	width: 100%;
	margin: $drop-down-padding;
	overflow: auto;
}
