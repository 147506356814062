// Copyright 2021-2024 Epic Systems Corporation

@use "../../styles/_colors.scss" as *;
@use "../../styles/_dimensions.scss" as *;

// Put 15 px between stacked cards. This is the more padding as between banners and cards (see BubbleBanner.module.scss) but looks less squished
.card + .card {
	margin-top: 15px;
}

.card {
	position: relative; // This makes the card a positioned element, so its children will be placed relative to it

	border: 1px solid rgba(0, 0, 0, 0.125);
	border-radius: 0.25rem;
	background: #fff border-box;
	box-shadow: 0 4px 4px -1px rgba(0, 0, 0, 0.2);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	text-align: center;
	word-wrap: break-word;

	// if not first child, no top margin (e.g. hardware test screen with warning bubble present)
	margin-top: 0px;
	// if first child, apply screen size based top margin
	&:first-child {
		@media only screen and (min-width: 920px) {
			margin-top: 5px;
		}
		@media only screen and (min-height: $minimum-screen-size-for-header) {
			& {
				margin-top: 85px;
				&.hasButton {
					margin-top: 50px;
				}
			}
			@media only screen and (min-height: 880px) {
				&,
				&.hasButton {
					margin-top: 110px;
				}

				@media (min-height: 1080px) {
					&,
					&.hasButton {
						margin-top: 170px;
					}
				}
			}
		}
	}

	font-size: 1.2rem;

	> *:first-child:not(div):not(button) {
		margin-top: 20px;
	}

	> *:not(div):not(button) {
		/* The next line is for IE 11, which doesn't appear to care if flex children overlap their parents.
		* We wouldn't bother, except we use this card on the 'browser not supported' page */
		max-width: 100%;
		margin: 5px 20px;
	}

	> *:last-child:not(div):not(button) {
		margin-bottom: 20px;
	}

	h1 {
		font-size: 2rem;
		color: $WPColor-ThemedHeader;
	}

	h2 {
		color: $WPColor-ThemedHeader;
		margin: 0;
		font-size: 1.3rem;
	}

	&.hasButton {
		border-bottom: none;
		border-bottom-left-radius: 15px;
		border-bottom-right-radius: 15px;
	}

	&.centered {
		margin-left: auto;
	}
}

// First font-size reduction
@media only screen and (max-height: 650px), (max-width: 450px) {
	.card {
		font-size: 1rem;

		h1 {
			font-size: 1.6rem;
		}

		h2 {
			font-size: 1.2rem;
		}
	}
}

// Second font-size reductions
@media only screen and (max-width: 400px) {
	.card {
		h1 {
			font-size: 1.4rem;
		}
		h2 {
			font-size: 1.1rem;
		}
	}
}

// Third font-size reductions, to avoid text wrap on the main card
@media only screen and (max-width: 350px) {
	.card {
		h1 {
			font-size: 1.2rem;
		}
		h2 {
			font-size: 1rem;
		}
	}
}
