// Copyright 2020-2022 Epic Systems Corporation

@use "../../styles/_colors.scss" as *;
@use "../../styles/_dimensions.scss" as *;

header {
	position: static;
	z-index: 90; // device permission warning (DevicePermissionWarningOverlay.module.scss) < this < image capture preview pane (ImagePreviewPane.module.scss)
	width: 100%;
}

.headerBar {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	background-color: $EVCColor-HeaderBack;

	display: flex;
	align-items: center;
	width: 100%;
	min-height: 61px;
	padding: 5px 10px;
}

.epicLogo {
	height: 35px;
}

.epicLogoWrapper {
	flex: 1;
	display: flex;
	align-items: center;
}

.childrenWrapper {
	display: flex;
	flex: 1;
	justify-content: flex-end;
}

// shrink the Epic logo on narrow screens and without a header
@media only screen and (max-height: $minimum-screen-size-for-header),
	only screen and (max-width: $screen-width-inflection) {
	.epicLogo {
		height: 30px;
	}
}

// minimize how much space the SimpleHeader will take up
@media only screen and (max-height: $minimum-screen-size-for-header) {
	.headerBar {
		padding-top: 0;
		padding-bottom: 0;
		min-height: unset;
		align-items: center;
	}
}
