// Copyright 2020 Epic Systems Corporation

@use "../../styles/_colors.scss" as *;

.hardwareSetupRow {
	display: flex;
	flex: 1;
	justify-content: space-between;
	align-items: center;
	padding: 0 5px 2px 5px;
	position: relative;
}

.iconAndLabelWrapper {
	display: flex;
	align-items: center;
}

.hardwareRowIcon {
	fill: $WPColor-TextBody;

	.hardwareSetupRow.dark & {
		fill: $WPColor-TextOnColoredBack;
	}
}

.StatusIconContainer {
	position: relative;
	width: fit-content;
}

.statusIcon {
	width: 16px;
	position: absolute;
	top: 14px;
	left: 0;
	border-radius: 100%;
	border: 1px solid #f8f8f8;
	fill: #ffffff;

	.hardwareSetupRow.dark & {
		border-color: $EVCColor-MenuBack;
		fill: $WPColor-TextStrong;
	}

	&.success {
		background-color: #52cc6e;
	}

	&.warning {
		background-color: #ffc000;
		fill: #363636;
	}

	&.error {
		background-color: #f55a63;
	}
}

.centerLabel {
	font-weight: bold;
}
