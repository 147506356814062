// Copyright 2021 Epic Systems Corporation

@use "../../styles/_dimensions.scss" as *;

.mirrored {
	transform: scaleX(-1);
}

.portraitFit {
	object-fit: contain;
}

.landscapeFit {
	object-fit: cover;
}

@media only screen and (max-width: $screen-width-inflection) and (orientation: portrait) {
	// Since we use cover for non-portrait remote feeds, any remote feeds in portrait have an unnecessary extra degree of padding
	// When we render remote video feeds in the horizontal participant strip, ensure they all are properly cropped via the cover attribute
	.portraitFit:not(.sharedScreen) {
		object-fit: cover !important;
	}
}
