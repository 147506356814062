// Copyright 2023 Epic Systems Corporation

@use "../../styles/_dimensions.scss" as *;
@import "../../styles/_colors.scss";

@mixin settingsPanelSpace {
	width: $hardware-test-settings-panel;

	// For width transition, the only way that seems to work is setting width to a constant
	// and transitioning the width property
	transition: width 0.4s ease 0s, visibility 0s linear 0.4s;
	&.panelClosed {
		width: 0;
	}
}

.settingsPanel {
	font-size: 1rem;

	overflow: hidden;

	@include settingsPanelSpace;
	position: relative;

	@media screen and (max-width: $screen-width-inflection) {
		width: unset;
		max-width: 100%;
		overflow: hidden;
		transition: max-height 0.4s ease 0s, visibility 0s linear 0.4s;
		max-height: 800px; // Rough max height, to make height scale exactly on time we need to set the max-height in javascript based on the inner div's size (with absolute positioning), see Collapse

		&.panelClosed {
			width: unset;
		}
	}
}

.panelContentHolder {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: $hardware-test-settings-panel;
	overflow-x: hidden;
	padding-left: 15px;

	@media screen and (max-width: $screen-width-inflection) {
		width: unset;
		position: unset;
		padding-left: unset;
	}
}

.toggleSettingsContent {
	height: 100%;
}

.tabContent {
	overflow-y: auto;
	padding: 0 10px 10px 10px;

	@media screen and (max-width: $screen-width-inflection) {
		padding: 0;
		margin-top: 15px;
	}
}

.tabButton {
	background-color: transparent;
	border-width: 0 0 2px 0;
	margin: 3px;
	color: $WPColor-TextStrong;

	cursor: pointer;

	&.tabActive {
		border-bottom: 3px solid $WPColor-BrandPrimary;
	}
}

.hidden {
	display: none;
}
