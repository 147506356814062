.ChatWrapper {
	height: 100%;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	overflow: hidden;
	ul {
		list-style-type: none;
	}
}
