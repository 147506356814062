// Copyright 2020-2024 Epic Systems Corporation

@use "../../styles/_dimensions.scss" as *;
@use "../../styles/_utilities.scss" as *;

$button-transition-duration: 0.25s;

// Mixin to set the video element/placeholder to the desired size
// Since actual video has variable aspect ratio, we only set height, and let it take the appropriate width on its own
// The placeholder needs both height and width set
@mixin videoSizes($height) {
	video {
		height: $height;
	}
	&.noVideo .videoPlaceholder {
		height: $height;
		width: $height * $float-video-placeholder-width-factor;
	}
}

.backgroundPreview {
	position: relative;
	border-radius: 10px;
	padding: 10px 0;
	display: flex;
	justify-content: center;
	overflow: hidden;

	@include videoSizes(($float-video-height-1));

	video {
		border-radius: 10px;

		// iOS cuts off part of the video unless we force width to be unset
		width: unset !important;
	}
}

.backgroundPreview,
.backgroundPreview video,
.videoPlaceholder {
	transition: height $button-transition-duration, width $button-transition-duration;
}

// Scale the video at smaller screen sizes
.backgroundPreview {
	// The card sometimes overlaps with the preview at this point
	// With the card centered, there's padding on the left of the card equal to the size of the preview
	// So these first two account for double the width of the floating video

	$overlapWidth-1: $card-width-large + (2 * $float-video-width-max-1);
	$overlapHeight-1: 1024px; // Special value chosen to account for iPads + iOS specific warnings
	@media only screen and (max-width: $overlapWidth-1) and (max-height: $overlapHeight-1) {
		@include videoSizes(($float-video-height-2));
	}

	$overlapWidth-2: $card-width-large + (2 * $float-video-width-max-2);
	$overlapHeight-2: $card-height-est + $header-height + $float-video-height-2 + 10px;
	@media only screen and (max-width: $overlapWidth-2) and (max-height: $overlapHeight-2) {
		@include videoSizes(($float-video-height-3));
	}

	// At certain sizes, the card narrows and shifts left from center
	// So at the point of overlap, there's no padding on the left
	$overlapWidth-3: $card-width-small + $float-video-width-max-3;
	$overlapHeight-3: $card-height-est + $header-height + $float-video-height-3 + 10px;
	@media only screen and (max-width: $overlapWidth-3) and (max-height: $overlapHeight-3) {
		@include videoSizes(($float-video-height-4));
	}

	// At even smaller sizes, overlap is possible again, but reducing the preview size at that point would render it pretty useless
}

.previewWrapper {
	display: inline-flex;
	position: relative;
}

.bannerText {
	position: absolute;
	bottom: 0;
	background-color: rgba($color: #000000, $alpha: 0.6);
	display: flex;
	justify-content: center;
	width: 100.1%;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
	cursor: default;
}
