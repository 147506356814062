// Copyright 2021 Epic Systems Corporation

@import "../../../styles/_colors.scss";
@import "../../../styles/_utilities.scss";
@import "../../../styles/_dimensions.scss";

.pageSelectorButton {
	height: $page-button-size;
	width: $page-button-size;
	padding: 0px;
	border: none;
	background: none;

	svg {
		fill: #fff;
		stroke: #000;
		@include focusedOrHovered() {
			stroke: WPLighten($carousel-blue, 40%);
		}
	}

	&.selected svg {
		cursor: default;
		fill: $carousel-blue;
		stroke: $carousel-blue;
	}
}

.pageSelector {
	display: flex;
}
