// Copyright 2022 Epic Systems Corporation

@use "../../styles/_dimensions.scss" as *;

.logoContainer {
	height: 50px;
	width: 25%;
}

@media only screen and (max-width: $screen-width-inflection) {
	.logoContainer {
		width: 40%;
	}
}

.logo {
	height: 100%;
	width: 100%;
	object-fit: contain;
}
