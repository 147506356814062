// Copyright 2021 Epic Systems Corporation

.down {
	transform: scaleY(-1);
}

.left {
	transform: rotate(-90deg);
}

.right {
	transform: rotate(90deg);
}
