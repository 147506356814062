// Copyright 2023 Epic Systems Corporation

@use "../../styles/_colors.scss" as *;
@use "../../styles/_utilities.scss" as *;

.cardButton {
	background-color: $WPColor-BorderedActionButtonDefaultBack;
	color: $WPColor-BorderedActionButtonDefaultFore;
	border: none;
	border-radius: 10px; // Make card buttons more fun and rounded
	box-shadow: 0 0 4px $WPColor-ShadowVerySoft; // Match drop shadow for selection controls in the case the button is a card
	outline: none;
	padding: 4px 12px; // Match standard padding values even though style should be secondary
	margin: 10px;
	display: flex;
	font-weight: 600; // Add more weight to card labels
	line-height: 1.5em;
	cursor: pointer;
	fill: currentColor;

	div {
		outline: none;
	}

	&:not(:last-child) {
		margin-right: 10px;
	}

	@include focusedOrHovered() {
		background-color: $WPColor-BorderedActionButtonDefaultHoverBack;
		color: $WPColor-BorderedActionButtonDefaultHoverFore;
	}
	&.active {
		background-color: $WPColor-BorderedActionButtonDefaultHoverBack;
		color: $WPColor-BorderedActionButtonDefaultHoverFore;
		border: 1px solid;
	}

	&.negative {
		color: $WPColor-BorderedActionButtonNegativeFore;

		@include focusedOrHovered() {
			background-color: $WPColor-BorderedActionButtonNegativeHoverBack;
			color: $WPColor-BorderedActionButtonNegativeHoverFore;
		}
		&.active {
			background-color: $WPColor-BorderedActionButtonNegativeHoverBack;
			color: $WPColor-BorderedActionButtonNegativeHoverFore;
		}
	}

	&.positive {
		color: $WPColor-BorderedActionButtonPositiveFore;

		@include focusedOrHovered() {
			background-color: $WPColor-BorderedActionButtonPositiveHoverBack;
			color: $WPColor-BorderedActionButtonPositiveHoverFore;
		}
		&.active {
			background-color: $WPColor-BorderedActionButtonPositiveHoverBack;
			color: $WPColor-BorderedActionButtonPositiveHoverFore;
		}
	}
}
