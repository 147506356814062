// Copyright 2021 Epic Systems Corporation

.outer {
	transition: max-height 0.4s ease 0s;
	overflow: hidden;
	width: 100%;

	&.collapsed {
		// When collapsing, delay visibility change until after transition finishes
		transition: max-height 0.4s ease 0s, visibility 0s linear 0.4s;
	}
}

.inner {
	padding: 1px 0 1px 0; // This captures the margins of any children, so height calculations are accurate
	margin: -1px 0 -1px 0; // And this removes off the extra padding
}
