// Copyright 2022-2024 Epic Systems Corporation

@import "../../styles/_dimensions.scss";

div.surveyContainer {
	padding: 0px 10% 60px 10%;
	position: relative;
	overflow: auto;

	.survey-card {
		width: fit-content;
		margin-left: auto;
		margin-right: auto;
		padding: 0 20px;

		.surveyWorkflow {
			width: 100%;
		}
	}

	@media screen and (max-width: $screen-width-inflection) {
		> .survey-card {
			margin-top: 10px;
			width: 100%;
			padding: 0;
		}
		padding: 0px 10px;
	}
}
