// Copyright 2021 Epic Systems Corporation

@use "../../../styles/_dimensions.scss" as *;
@import "../../Header/Menu/_menuUtils.scss";

.sectionWrapper {
	height: 100%;
	background: rgba(0, 0, 0, 0.9);
	display: flex;
	flex-direction: column;
}

.gridWrapper {
	flex-grow: 1;
	width: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding-bottom: 50px;

	&.sidebarOpen {
		width: calc(100% - $menu-width); // when sidebar is open
	}
}

.videoGrid {
	display: flex;
	align-content: center;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	vertical-align: middle;
}
